import React, { useEffect, useState } from "react";
import Div from "../../div";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getAllService } from "../../../api/service/api";
import { fixedServices } from "../mate-data/mateData";

function DentalService({ isServicePage }) {
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllService().then((res) => {
      const data = res.data;
      data
        ? setServices(
            data.map((e, i) => {
              if (i < 6) {
                e.firstImage = fixedServices[i].firstImage;
                e.secondImage = fixedServices[i].secondImage;
                return e;
              }
            })
          )
        : setServices(fixedServices);
    });
  }, []);

  return (
    <Div>
      <Div className='my-container py-[30px]'>
        <Div className='text-center mb-[30px]'>
          <h2 className='mb-[10px] text-2xl text-p-color font-bold lg:text-4xl'>
            DỊCH VỤ NHA KHOA
          </h2>
          <p className='lg:text-lg'>
            Nơi nụ cười của bạn là một tác phẩm nghệ thuật. Làm đẹp nụ cười -
            Thay đổi cuộc sống.
          </p>
        </Div>

        <Div className='mt-[30px]'>
          <ul className='flex flex-col gap-[40px] md:flex-row md:gap-0 flex-wrap'>
            {services?.map((e, i) => {
              if (e?.description?.length >= 220) {
                const truncatedDescription = e.description.slice(0, 220);
                const lastSpaceIndex = truncatedDescription.lastIndexOf(" ");
                e.description =
                  lastSpaceIndex !== -1
                    ? truncatedDescription.slice(0, lastSpaceIndex) + "..."
                    : truncatedDescription + "...";
              }

              return (
                <li key={i} className='basis-[33.3%]'>
                  <Div
                    onClick={() =>
                      navigate({
                        pathname: "/content-web",
                        search: `?${createSearchParams({
                          content: e.id,
                          type: "dental-service",
                        }).toString()}`,
                      })
                    }
                    className={styles.links}
                  >
                    <Div className='relative h-[40px]'>
                      <img
                        className={styles.firstImg}
                        src={e?.firstImage}
                        alt=''
                      />

                      <img
                        className={styles.secondImg}
                        src={e?.secondImage}
                        alt=''
                      />
                    </Div>
                    <Div>
                      <h3 className={styles.serviceTitle}>{e?.name}</h3>

                      <Div
                        className={styles.serviceDes}
                        dangerouslySetInnerHTML={{ __html: e?.description }}
                      ></Div>
                    </Div>
                  </Div>
                </li>
              );
            })}
          </ul>

          {!isServicePage && (
            <Div className='text-center mt-[20px]'>
              <button
                onClick={() => navigate("/service")}
                className={styles.readMoreBtn}
              >
                Xem thêm
              </button>
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  links:
    "flex flex-col relative items-center gap-[20px] p-[20px] md:p-[25px] lg:p-[40px] overflow-hidden before:absolute before:content-[''] before:w-full before:h-full before:top-0 before:left-[50%] before:translate-x-[-200%] before:-z-10 before:bg-linear-color hover:before:translate-x-[-50%] before:duration-500 group cursor-pointer",
  logoImg: "absolute top-0 right-0 p-[10px]",
  firstImg:
    "max-w-[50px] object-contain absolute left-[50%] translate-x-[-50%] group-hover:invisible",
  secondImg:
    "max-w-[50px] object-contain absolute left-[50%] translate-x-[-50%] invisible group-hover:visible",
  serviceTitle:
    "text-xl text-center font-bold text-p-color mb-[10px] group-hover:text-white duration-500 lg:text-2xl",
  serviceDes:
    "text-sm group-hover:text-white duration-500 lg:text-base overflow-wrap-break-word",
  readMoreBtn:
    "pt-[7px] pb-[10px] px-[15px] border-2 border-p-color rounded-full text-p-color font-bold hover:text-white hover:bg-p-color duration-300 lg:px-[40px] lg:pt-[11px] lg:pb-[14px] lg:text-lg",
};

export default DentalService;
