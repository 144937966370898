import React from "react";
import Div from "../div";

function Map() {
  return (
    <Div>
      <Div className="my-container pt-[60px]">
        <Div className="">
          <Div className="w-full h-[250px] md:h-[480px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29814.792556415076!2d106.78424676292533!3d20.91839111499286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314a6358e85a4ecf%3A0xf370a5f3e28f20ac!2sNha%20khoa%20Ph%E1%BA%A5n%20Hoa!5e0!3m2!1svi!2s!4v1702078330086!5m2!1svi!2s"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full h-full"
            ></iframe>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

export default Map;
