import React from "react";
import Div from "../../div";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

function HotlineBanner() {
  return (
    <Div className="bg-p-color">
      <Div className="my-container h-[120px] flex flex-col items-center justify-center text-white gap-[10px]">
        <Link to={""}>
          <h2 className="text-2xl font-bold lg:text-4xl">
            Hotline tư vấn miễn phí
          </h2>
        </Link>

        <Link
          to={"tel:0971759666"}
          className="flex items-center gap-[10px] group"
        >
          <span className="text-xl px-[10px] py-[10px] rounded-full border border-white group-hover:scale-110 duration-500">
            <FaPhoneAlt />
          </span>

          <span className="uppercase text-xl">BẤM GỌI NGAY</span>
        </Link>
      </Div>
    </Div>
  );
}

export default HotlineBanner;
