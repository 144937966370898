import React from "react";
import Div from "../../div";
import Image2 from "../../../asset/logo-and-service/DSC_8919.jpg";
import Image1 from "../../../asset/logo-and-service/clinic3.jpg";

function PeopleAbout() {
  const prestige = [
    {
      content:
        "Hiện nay, Nha Khoa Phấn Hoa gồm hơn 150 nhân viên nhiệt tình, có trình độ chuyên môn cao, nhiệt huyết và tỉ mỉ  trong từng chi tiết sản phẩm, dịch vụ nha khoa. Đội ngũ bác sĩ ở Nha khoa Phấn Hoa luôn được kiểm tra năng lực định kỳ, thường xuyên tham gia các tổ chức huấn luyện nâng cao tay nghề cũng như trình độ kỹ thuật và kiến thức chuyên môn được giảng dạy bởi các chuyên gia nước ngoài để đảm bảo quy trình điều trị đạt hiệu quả tối ưu mang đến trải nghiệm tốt nhất cho khách hàng.",
    },
  ];

  const solidarity = [
    {
      content:
        "Môi trường đảm bảo an toàn lao động, sang trọng và lịch sự. Không gian được thiết kế riêng biệt và đồng bộ cho từng bác sĩ. Khu vực sảnh chờ tại mỗi tầng cũng được đầu tư trang thiết bị hiện đại cũng như cơ sở vật chất tại mỗi tầng cũng được đầu tư và trau chuốt kỹ lưỡng. Mang đến sự trải nghiệm tuyệt vời, nâng cao đời sống chất lượng tinh thần cho cán bộ nhân viên phòng khám.",
    },
  ];

  const creative = [
    {
      content:
        "Ở Nha Khoa Phấn Hoa, chúng tôi lấy con người làm cốt lõi của sự phát triển, vì vậy đặt sự ưu tiên trong việc đào tạo và tái đào tạo cho toàn bộ đội ngũ từ mọi vị trí trong phòng khám.",
    },
  ];

  return (
    <Div>
      <Div className="my-container py-[30px] lg:py-[60px]">
        <Div>
          <h1 className={styles.aboutPageTitle}>VỀ CON NGƯỜI</h1>
        </Div>

        <article>
          <Div className="lg:flex">
            <Div className="lg:basis-[65%]">
              <Div
                className={`${styles.sectionBox} mt-[50px] after:bottom-[-12.5px] after:bg-p-color`}
              >
                <h2 className={styles.sectionTitle}>CON NGƯỜI TẠI ĐÂY</h2>

                <ul className=" duration-300 group-hover:text-white">
                  {prestige.map((e, i) => {
                    return (
                      <li key={i} className="mb-[10px]">
                        <p className="text-sm md:text-base lg:text-lg">
                          {e.content}
                        </p>
                      </li>
                    );
                  })}
                </ul>

                <Div className={`${styles.slideBg} bottom-0`}></Div>
              </Div>

              <Div className="w-full h-[629px] mt-[20px] md:mt-0">
                <img className={styles.image} src={Image1} alt="" />
              </Div>
            </Div>

            <Div className="lg:basis-[35%]">
              <Div
                className={`${styles.sectionBox} mt-[50px] after:bottom-[-12.5px] after:bg-p-color`}
              >
                <Div>
                  <h2 className={styles.sectionTitle}>MÔI TRƯỜNG LÀM VIỆC</h2>
                </Div>

                <ul className="pl-[10px] text-sm duration-300 group-hover:text-white md:text-base lg:text-lg">
                  {solidarity.map((e, i) => {
                    return (
                      <li key={i} className="mb-[10px]">
                        <p className="text-sm md:text-base lg:text-lg">
                          {e.content}
                        </p>
                      </li>
                    );
                  })}
                </ul>

                <Div className={`${styles.slideBg} bottom-0`}></Div>
              </Div>

              <Div className="w-full h-[280px] mt-[30px] md:mt-0">
                <img className={styles.image} src={Image2} alt="" />
              </Div>

              <Div>
                <Div
                  className={`${styles.sectionBox} mt-[40px] after:top-[-12.5px] after:bg-white lg:mt-[20px]`}
                >
                  <Div>
                    <h2 className={styles.sectionTitle}>
                      ĐÀO TẠO VÀ TÁI ĐÀO TẠO
                    </h2>
                  </Div>

                  <ul className="ml-[10px] text-sm duration-300 group-hover:text-white md:text-base lg:text-lg">
                    {creative.map((e, i) => {
                      return (
                        <li key={i} className="mb-[5px] last:mb-0">
                          {e.content}
                        </li>
                      );
                    })}
                  </ul>

                  <Div className={`${styles.slideBg} top-0`}></Div>
                </Div>
              </Div>
            </Div>
          </Div>
        </article>
      </Div>
    </Div>
  );
}

const styles = {
  aboutPageTitle:
    "uppercase text-p-color text-2xl font-bold text-center md:text-3xl lg:text-4xl",
  sectionBox:
    "overflow-hidden relative group p-[10px] md:px-[30px] md:py-[70px] after:hidden md:after:block after:content-[''] after:absolute after:left-[50%] after:translate-x-[-50%] after:rotate-[45deg] after:w-[25px] after:h-[25px] hover:after:bg-white after:duration-500",
  sectionTitle:
    "uppercase text-p-color text-xl font-bold text-center mb-[20px] duration-300 group-hover:text-white md:text-2xl",
  slideBg:
    "w-full h-0 absolute left-[50%] translate-x-[-50%] bottom-0 bg-p-color -z-10 duration-300 group-hover:h-full",
  image: "w-full h-full object-cover object-center",
};

export default PeopleAbout;
