import React from "react";
import "./reactSlickStyle.css";
import Div from "../div";
import Slider from "react-slick";
import { GrNext, GrPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";
import { doctorList } from "../common/mate-data/mateData";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <Div
      onClick={onClick}
      className="absolute right-[-10px] top-[50%] text-white bg-p-color py-[8px] pl-[10px] pr-[8px] rounded-full z-10 cursor-pointer"
    >
      <GrNext />
    </Div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <Div
      onClick={onClick}
      className="absolute left-[-10px] top-[50%] text-white bg-p-color py-[8px] pl-[8px] pr-[10px] rounded-full z-10 cursor-pointer"
    >
      <GrPrevious />
    </Div>
  );
};

function DoctorCarousel() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Div className="my-[30px]">
      <Slider {...settings}>
        {doctorList.map((e, i) => {
          return (
            <Div key={i} className="h-[450px] group">
              <Div className="h-[350px] overflow-hidden">
                <Link to={""}>
                  <img
                    className="w-full h-full object-cover group-hover:scale-110 duration-700"
                    src={e.image}
                    alt=""
                  />
                </Link>
              </Div>

              <Div className="flex flex-col items-center justify-center gap-[8px] h-[100px] py-[20px] px-[15px] bg-[#e3e3e3]">
                <Link to={""}>
                  <p className="font-bold text-lg group-hover:text-p-color duration-300">
                    {e.doctorName}
                  </p>
                </Link>
                <p>{e.doctorPosition}</p>
              </Div>
            </Div>
          );
        })}
      </Slider>
    </Div>
  );
}

export default DoctorCarousel;
