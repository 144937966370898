import * as ServiceIcons from "./service-icons";
import Doctor1 from "../../../asset/logo-and-service/Bs CK1 _ Vũ Văn Phấn.png";
import Doctor2 from "../../../asset/logo-and-service/DSC_8630.png";
import Doctor3 from "../../../asset/logo-and-service/DSC_8661.png";
import Doctor4 from "../../../asset/logo-and-service/DSC_8857.png";
import Customer1 from "../../../asset/logo-and-service/customer-1.jpg";
import Customer2 from "../../../asset/logo-and-service/customer-2.jpg";
import Customer3 from "../../../asset/logo-and-service/customer-3.jpg";
import Customer4 from "../../../asset/logo-and-service/customer-4.jpg";
import Customer5 from "../../../asset/logo-and-service/customer-5.jpg";

export const fixedServices = [
  {
    name: "Cấy Ghép Implant",
    firstImage: ServiceIcons.ServiceIcon1,
    secondImage: ServiceIcons.ServiceIcon2,
    description:
      " Cấy ghép Implant là kỹ thuật trồng răng giả được thực hiện bằng cách cấy ghép một chân răng giả làm bằng titanium vào bên trong xương hàm ở vị trí răng đã mất để tạo ra các chân răng nhân tạo rồi gắn lên nó các chiếc răng giả cố định với mục đích thay thế răng đã bị mất đi...",
  },
  {
    name: "Nhổ Răng Khôn",
    firstImage: ServiceIcons.ServiceIcon3,
    secondImage: ServiceIcons.ServiceIcon4,
    description:
      "Thực chất răng khôn là tên gọi được dùng để chỉ những chiếc răng hàm mọc cuối cùng của mỗi bên hàm, hay gọi là răng số 8. Chiếc răng này không xuất hiện ở trẻ nhỏ khi mới mọc răng hay khi đã thay răng mà xuất hiện cuối cùng, thường ở người trưởng thành 18 tuổi trở lên...",
  },
  {
    name: "Chỉnh Nha Invisalign",
    firstImage: ServiceIcons.ServiceIcon5,
    secondImage: ServiceIcons.ServiceIcon6,
    description:
      " Chỉnh nha Invisalign hay còn gọi là niềng răng trong suốt là phương pháp không cần sử dụng đến dây thun hay mắc cài như phương pháp truyền thống, thay vào đó bạn sẽ dùng khay nhựa trong suốt được đo đạc theo đúng đặc điểm, kích thước khuôn răng của bạn. Đặc biệt trong quá trình ăn uống hay vệ sinh răng miệng, bạn hoàn toàn có thể tháo khay niềng ra....",
  },
  {
    name: "Phục Hình Răng Sứ Thẩm Mỹ",
    firstImage: ServiceIcons.ServiceIcon7,
    secondImage: ServiceIcons.ServiceIcon8,
    description:
      "Trong nha khoa, phục hình răng là kỹ thuật khôi phục lại hình thể của răng bị tổn thương nhằm tái tạo lại thẩm mỹ và chức năng ăn nhai của răng. Răng sẽ được khắc phục những khuyết điểm, có lại được hình dáng, màu sắc như ban đầu, đồng thời cải thiện sức khỏe răng miệng...",
  },
  {
    name: "Dán Sứ Veneer",
    firstImage: ServiceIcons.ServiceIcon9,
    secondImage: ServiceIcons.ServiceIcon10,
    description:
      "Mặt dán sứ Veneer (Laminate sứ) là vật liệu có dạng vỏ mỏng với độ dày từ 0.3 - 0.5mm, giống với màu răng, được gắn lên bề mặt ngoài của răng, ôm vừa khít toàn thân răng. Nó giúp cải thiện hình dáng răng và đạt được tính thẩm mỹ như mong muốn. Veneer thường được làm từ sứ, composite hoặc nhựa tổng hợp, được gắn vĩnh viễn lên răng....",
  },
  {
    name: "Chỉnh Nha Mắc Cài",
    firstImage: ServiceIcons.ServiceIcon11,
    secondImage: ServiceIcons.ServiceIcon12,
    description:
      "Mắc cài kim loại thường là loại mắc cài cơ bản, chất liệu thường là inox, thép không gỉ hay đôi khi là bằng bạc hoặc bằng vàng. Khung kim loại của mắc cài kim loại có khả năng chịu lực tốt. Loại mắc cài này sử dụng dây thun buộc cố định dây cung trong rãnh mắc cài từ đó tạo ra lực kéo để di chuyển răng về vị trí mong muốn...",
  },
];

export const doctorList = [
  {
    image: Doctor1,
    doctorName: "Dr. Vũ Văn Phấn",
    doctorPosition: "Bác sĩ chuyên khoa",
  },
  {
    image: Doctor2,
    doctorName: "Dr. Duy",
    doctorPosition: "Bác sĩ chuyên khoa",
  },
  {
    image: Doctor3,
    doctorName: "Dr. Nguyễn Văn Linh",
    doctorPosition: "Bác sĩ chuyên khoa",
  },
  {
    image: Doctor4,
    doctorName: "Nguyễn Ngọc Tuyên",
    doctorPosition: "Điều dưỡng trưởng",
  },
];
export const reviews = [
  {
    avatar: Customer1,
    reviewerName: "Khách hàng",
    reviewerJob: "Kinh doanh",
    description:
      "Nha khoa rộng rãi, thoáng mát. Cơ sở vật chất hiện đại. Đội ngũ nhân viên chu đáo nhiệt tình, rất đáng để trải nghiệm.",
  },
  {
    avatar: Customer4,
    reviewerName: "Khách hàng",
    reviewerJob: "Nhân viên kinh doanh",
    description:
      "Một nụ cười đẹp mang lại rất nhiều năng lượng tích cực. Tôi cảm nhận rõ rệt được điều đó. Cảm ơn đội ngũ y bắc sỹ của Nha Khoa Phấn Hoa.",
  },
  {
    avatar: Customer2,
    reviewerName: "Khách hàng",
    reviewerJob: "Nhân viên kinh doanh",
    description:
      "Bây giờ thì mình đã tự tin selfie dù vẫn đang trong quá trình niềng răng bởi nó cũng khá là thời trang nha. Thanks Nha Khoa Phấn Hoa.",
  },
  {
    avatar: Customer3,
    reviewerName: "Khách hàng",
    reviewerJob: "Sinh viên",
    description:
      "Trước đến giờ mình chẳng bao giờ dám cười tươi, từ ngày đến Nha Khoa Phấn Hoa thì thực sự đó chẳn còn là vấn đề nữa rồi.",
  },
  {
    avatar: Customer5,
    reviewerName: "Khách hàng",
    reviewerJob: "Nhân viên kinh doanh",
    description:
      "Được người bạn thân giới thiệu đến Nha Khoa Phấn Hoa em thấy hoàn toàn hài lòng về dịch vụ cũng như chất lượng. Đặc biệt cảm mến các bác sỹ tại nha khoa. Em xin cảm ơn các bác sĩ.",
  },
];
export const coreValues = [
  {
    title: "Tâm",
    content:
      "Lấy đạo đức và tâm của con người làm gốc rễ để phát triển bền vững trong lĩnh vực y tế.",
  },
  {
    title: "Trí",
    content: "Điều trị bằng trí tuệ và khối óc của các Bác Sĩ Chuyên Khoa.",
  },
  {
    title: "Tín",
    content:
      "Coi trọng và bảo vệ uy tín với khách hàng về chất lượng,tiến độ và các cam kết trong dịch vụ y tế.",
  },
  {
    title: "Tầm",
    content:
      "Với tầm nhìn và sứ mệnh được xác định rõ ràng giúp thu hút nhân tài đồng hành, cống hiến cùng Doanh Nghiệp. Đầu tư khoa học, hiện đại, bài bản mang tầm vóc Quốc Tế.",
  },
];

export const vision = [
  {
    content:
      "Phát triển hệ thống y tế với quy mô, trí tuệ và dịch vụ đi đầu khu vực.",
  },
  { content: "Xây dựng hệ thống để nhân bản rộng khắp cả nước." },
  {
    content:
      "Tiên phong số 1 về công nghệ trong điều trị, thẩm mỹ nha khoa tại Việt Nam và trên toàn Thế giới.",
  },
];

export const mission = [
  { content: "Chăm sóc, bảo vệ và gìn giữ từng đơn vị răng của người Việt" },
  {
    content:
      "Cam kết mang đến cho các khách hàng sự hài lòng về chất lượng dịch vụ cũng như chất lượng sản phẩm.",
  },
];

export const prestige = [
  {
    title: "Uy tín",
    content:
      "Đảm bảo chất lượng sản phẩm tốt nhất. Tuân thủ thời gian điều trị chính xác. Làm việc và vận hành có hệ thống chuẩn. Mối quan hệ win-win đôi bên cùng có lợi.",
  },
  {
    title: "Dẫn đầu",
    content:
      "Luôn đầu tư công nghệ tiên tiến bậc nhất nhất trên thế giới. Đặt mục tiêu chất lượng hàng đầu. Mang lại các trải nghiệm tốt nhất tới các khách hàng.",
  },
];

export const solidarity = [
  {
    title: "Đoàn kết",
    content:
      "Cùng chung mục tiêu, chí hướng. Luôn vì mình và vì mọi người. Luôn có tinh thần hợp tác. Không đối kháng - Tôn trọng lẫn nhau.",
  },
  {
    title: "Trách nhiệm",
    content:
      "Luôn chịu trách nhiệm với hành động của mình. Không đổ lỗi - Không bao biện. Hoàn thành công việc tới cùng.",
  },
];

export const creative = [
  {
    content:
      "Luôn tìm tòi, học hỏi, phát huy tính sáng tạo. Luôn động viên, khuyến khích ý tưởng mới. Cập nhật và học tập các kiến thức mới, công nghệ mới.",
  },
];
