import React from "react";
import Div from "../../div";
import ReviewCarousel from "../../carousel/ReviewCarousel";

function Review() {
  return (
    <Div>
      <Div className={styles.container}>
        <h2 className={styles.title}>KHÁCH HÀNG NÓI VỀ NHA KHOA PHẤN HOA</h2>

        <p className="text-center lg:text-lg">
          Những chia sẻ cảm nhận của khách hàng sau khi sử dụng các dịch vụ tại
          Nha Khoa Phấn hoa. Nha Khoa Phấn Hoa.
        </p>
      </Div>

      <Div>
        <ReviewCarousel />
      </Div>
    </Div>
  );
}

const styles = {
  container: "my-container py-[40px] h-[500px] lg:h-[460px]",
  title:
    "uppercase text-p-color font-bold text-[23px] text-center mb-[10px] md:text-[26px] lg:text-4xl",
};

export default Review;
