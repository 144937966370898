import React, { useEffect } from "react";
import NavigateBanner from "../../common/navigate-banner";
import Map from "../../map";
import ContactInfo from "./ContactInfo";
import FaqForm from "./FaqForm";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <section>
        <NavigateBanner navigatePage={"Liên hệ"} />
      </section>

      <section>
        <Map />
      </section>

      <section>
        <ContactInfo />
      </section>

      <section>
        <FaqForm />
      </section>
    </main>
  );
}

export default Contact;
