import React, { useRef, useState } from "react";
import Div from "../../div";
import { FaUser } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaStar } from "react-icons/fa6";
import { PiNotePencilBold } from "react-icons/pi";
import ReCAPTCHA from "react-google-recaptcha";
import { message } from "antd";
import { createContact } from "../../../api/contacts/api";

function FaqForm() {
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef();
  const [tokenCapcha, setTokenCapcha] = useState();

  const [values, setValues] = useState({
    userName: "",
    phoneNumber: "",
    email: "",
    content: "",
  });

  const formErrors = {
    userName: "",
    phoneNumber: "",
    email: "",
    content: "",
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const emailRegex = /^([\w.+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/;
  const phoneRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      values.userName === "" ||
      values.phoneNumber === "" ||
      values.email === ""
    ) {
      message.error("Vui lòng điền đầy đủ thông tin để được tư vấn!");
      setIsLoading(false);
      return;
    }

    if (
      !phoneRegex.test(values.phoneNumber) ||
      !emailRegex.test(values.email)
    ) {
      message.error("Số điện thoại hoặc email không hợp lệ vui lòng xem lại!");
      setIsLoading(false);
      return;
    }

    if (!tokenCapcha) {
      message.error("Vui lòng xác thực lại!");
      setIsLoading(false);
      return;
    }

    const value = { ...values, recaptchaValue: tokenCapcha, type: 5 };

    createContact(value)
      .then(() => {
        message.success("Gửi thắc mắc thành công");
        recaptchaRef.current.reset();
        setValues({
          userName: "",
          phoneNumber: "",
          email: "",
          name: "general",
          content: "",
        });
      })
      .catch(() => {
        message.error("Gửi thắc mắc thất bại!");
      })
      .finally(() => {
        setIsLoading(false);
        setTokenCapcha("")});
  };

  function onChange(value) {
    setTokenCapcha(value);
  }

  return (
    <Div>
      <Div className="my-container mb-[40px] lg:mb-[60px] lg:mt-[20px]">
        <Div>
          <Div>
            <h1 className="text-p-color text-[23px] text-center font-bold lg:text-3xl">
              GỬI THẮC MẮC ĐẾN CHO CHÚNG TÔI
            </h1>

            <p className="text-lg text-center pt-[15px]">
              Để nhận được hỗ trợ tư vấn quý khách vui lòng điền vào form bên
              dưới :
            </p>
          </Div>

          <Div className="w-full lg:mt-[20px]">
            <form onSubmit={(e) => handleSubmit(e)}>
              <Div className="grid grid-cols-12 gap-[20px]">
                <Div className={styles.contactItemBox}>
                  <Div className={styles.inputBox}>
                    <input
                      value={values.userName}
                      onChange={(e) => handleChange(e)}
                      name="userName"
                      className={styles.inputs}
                      type="text"
                      placeholder="Họ và tên"
                    />

                    <Div className={styles.icons}>
                      <FaUser />
                    </Div>
                  </Div>
                  {formErrors.userName && (
                    <span className={styles.errorMsg}>
                      {formErrors.userName}
                    </span>
                  )}
                </Div>

                <Div className={styles.contactItemBox}>
                  <Div className={styles.inputBox}>
                    <input
                      value={values.email}
                      onChange={(e) => handleChange(e)}
                      name="email"
                      className={styles.inputs}
                      type="text"
                      placeholder="Email"
                    />

                    <Div className={styles.icons}>
                      <IoIosMail />
                    </Div>
                  </Div>

                  {formErrors.email && (
                    <span className={styles.errorMsg}>{formErrors.email}</span>
                  )}
                </Div>

                <Div className={styles.contactItemBox}>
                  <Div className={styles.inputBox}>
                    <input
                      value={values.phoneNumber}
                      onChange={(e) => handleChange(e)}
                      name="phoneNumber"
                      className={styles.inputs}
                      type="text"
                      placeholder="Số điện thoại"
                    />

                    <Div className={styles.icons}>
                      <FaStar />
                    </Div>
                  </Div>

                  {formErrors.phoneNumber && (
                    <span className={styles.errorMsg}>
                      {formErrors.phoneNumber}
                    </span>
                  )}
                </Div>
              </Div>
              <Div
                className={`w-full border-2 rounded-lg px-[10px] pb-[10px] flex items-start mt-[20px] lg:h-[200px]`}
              >
                <textarea
                  value={values.content}
                  onChange={(e) => handleChange(e)}
                  name="content"
                  className={`${styles.inputs} h-full resize-none md:pl-[10px]`}
                  placeholder="Nội dung"
                ></textarea>

                <Div className="text-p-color text-2xl mt-[10px]">
                  <PiNotePencilBold />
                </Div>
              </Div>

              <Div>
                <Div className="flex justify-center pt-[25px] lg:pt-[40px]">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY_CAPCHA}
                    onChange={onChange}
                    ref={recaptchaRef}
                  />
                </Div>
              </Div>

              <Div className="mt-[30px] text-center">
                <button
                  className="pt-[10px] pb-[12px] px-[25px] bg-p-color text-white rounded-lg font-semibold lg:py-[14px] lg:px-[35px] bg-right bg-gradient-to-r from-[#3866c9_50%] to-[#1d48a6_0] bg-[length:200%_200%] hover:bg-left duration-300"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Đang gửi..." : "Gửi ngay"}
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  contactItemBox: "col-span-12 md:col-span-4",
  inputBox:
    "border-2 rounded-lg px-[10px] flex items-center mt-[20px] lg:pl-[20px]",
  icons: "text-p-color text-xl lg:text-2xl pl-[10px] md:pl-0 lg:pl-[10px]",
  inputs: "flex-1 py-[15px] outline-none lg:py-[20px]",
  errorMsg:
    "relative top-[2px] left-0 pt-[2px] pb-[5px] px-[10px] bg-p-color text-white font-normal z-10 before:content-[''] before:bg-p-color before:w-[10px] before:h-[10px] before:absolute before:top-[-5px] before:left-[50%] before:translate-x-[-50%] before:rotate-45 before:-z-10",
};

export default FaqForm;
