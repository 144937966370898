import {
  Button,
  Col,
  Empty,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import AddService from "./content/Service";
import { columnService } from "../common/columns";
import { deleteService, getAllService } from "../../../api/service/api";
import dayjs from "dayjs";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const Service = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsvisible] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!isVisible) getData();
  }, [isVisible]);

  function getData() {
    setLoading(true);
    getAllService()
      .then((res) => {
        setDataSource(mapDataSource(res?.data));
      })
      .finally(() => setLoading(false));
  }

  const mapDataSource = (values) => {
    return values?.map((item) => ({
      ...item,
      key: item.id,
      createDate: dayjs(item.createdAt).format("DD-MM-YYYY"),
      menu: (
        <>
          <Row gutter={12} justify={"end"} align={"middle"}>
            <Col>
              <EditOutlined
                onClick={() => {
                  setData(item);
                  setIsvisible(true);
                }}
              />
            </Col>
            <Col>
              <DeleteOutlined onClick={() => confirmDelete(item.id)} />
            </Col>
          </Row>
        </>
      ),
    }));
  };

  function confirmDelete(id) {
    Modal.confirm({
      title: "Xác nhận",
      content: "Bạn có chắc là muốn xóa dịch vụ này không?",
      okText: <div style={{ color: "white" }}>Xóa</div>,
      okButtonProps: {
        className: "bg-admin-btn-primary",
      },
      onOk: () => {
        deleteService(id).then((res) => {
          message.success("Xóa dịch vụ thành công");
          getData();
        });
      },
    });
  }

  const locale = {
    emptyText: (
      <Space direction="vertical" align="center" style={{ marginTop: "20px" }}>
        <Empty description={"Chưa có dữ liệu dịch vụ"} />
      </Space>
    ),
  };

  return (
    <>
      <div style={{ padding: "10px" }}>
        <Button type="dashed" onClick={() => setIsvisible(true)}>
          Tạo mới dịch vụ
        </Button>
      </div>
      <Spin tip="Xin vui lòng chờ..." spinning={loading}>
        <Table
          columns={columnService}
          dataSource={dataSource}
          locale={locale}
        />
      </Spin>
      <AddService
        isVisible={isVisible}
        onCancel={() => {setIsvisible(false)
        setData(undefined)}}
        data={data}
      />
    </>
  );
};
export default Service;
