import React, { useState } from "react";
import Div from "../../div";
import ClinicCarousel from "../../carousel/ClinicCarousel";
import { IoIosClose } from "react-icons/io";
import ClinicBackground from "../../../asset/background/clinic-bg.jpg";
import * as ClinicImg from "./clinic-images";

function Clinic() {
  const [imageIndex, setImageIndex] = useState(1);
  const [showImage, setShowImage] = useState(false);

  const closeScaleImg = (e) => {
    const target = e.target;

    if (!target.classList.contains("scale-img")) {
      setShowImage(false);
    }
  };

  const listClinic = [
    {
      img: ClinicImg.Clinic1,
    },
    {
      img: ClinicImg.Clinic2,
    },
    {
      img: ClinicImg.Clinic3,
    },
    {
      img: ClinicImg.Clinic4,
    },
    {
      img: ClinicImg.Clinic5,
    },
    {
      img: ClinicImg.Clinic6,
    },
    {
      img: ClinicImg.Clinic7,
    },
    {
      img: ClinicImg.Clinic8,
    },
    {
      img: ClinicImg.Clinic9,
    },
    {
      img: ClinicImg.Clinic10,
    },
    {
      img: ClinicImg.Clinic11,
    },
    {
      img: ClinicImg.Clinic12,
    },
  ];

  return (
    <Div className="bg-[#f7f9fb] h-[620px] lg:h-[920px]">
      <Div
        onClick={(e) => closeScaleImg(e)}
        className={`fixed top-0 left-0 w-screen h-screen bg-black/50 overflow-hidden flex items-center justify-center z-50 duration-500 ${
          showImage ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <Div
          onClick={() => setShowImage(false)}
          className="absolute top-[10px] right-[30px] text-white text-5xl cursor-pointer"
        >
          <IoIosClose />
        </Div>

        <img
          className={`scale-img max-h-[80%] duration-500 ${
            showImage ? "scale-1" : "scale-0"
          }`}
          src={listClinic[imageIndex].img}
          alt=""
        />
      </Div>

      <Div
        style={{
          backgroundImage: `linear-gradient(#1d48a699,#1d48a699),url(${ClinicBackground})`,
        }}
        className={styles.banner}
      ></Div>

      <Div className={styles.clinicBox}>
        <h2 className={styles.title}>HÌNH ẢNH PHÒNG KHÁM</h2>

        <Div>
          <ClinicCarousel
            listClinic={listClinic}
            setImageIndex={setImageIndex}
            setShowImage={setShowImage}
          />
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  banner: "bg-cover bg-center h-[250px] lg:h-[340px]",
  clinicBox:
    "relative w-full top-[-150px] md:top-[60px] left-[50%] translate-x-[-50%] pb-[30px] lg:top-[-180px]",
  title:
    "uppercase text-center text-white text-2xl font-bold mb-[20px] lg:text-5xl lg:mb-[60px]",
};

export default Clinic;
