import { Col, Form, Input, Modal, Row, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { getAllService } from "../../../../api/service/api";
import { Option } from "antd/es/mentions";
import {
  updatePriceService,
  postPriceService,
} from "../../../../api/price-service/api";

const AddPrice = (props) => {
  const [form] = Form.useForm();
  const [dataService, setDataService] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllService().then((res) => {
      setDataService(res?.data);
    });
  }, []);

  useEffect(() => {
    if (props.data && props.isVisible) {
      form.setFieldsValue(props.data);
    } else {
      form.resetFields();
    }
  }, [form, props.data, props.isVisible]);

  const onFinish = (values) => {
    setLoading(true);
    if (props.data) {
      updatePriceService(props.data.id, values)
        .then((res) => {
          message.success("Sửa bảng giá thành công");
          form.resetFields();
          props.onCancel();
        })
        .finally(() => setLoading(false));
    } else {
      postPriceService(values)
        .then((res) => {
          message.success("Thêm bảng giá thành công");
          form.resetFields();
          props.onCancel();
        })
        .finally(() => setLoading(false));
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title={props.data ? "Sửa bảng giá" : "Thêm bảng giá"}
      centered
      open={props.isVisible}
      onOk={() => form.submit()}
      onCancel={props.onCancel}
      destroyOnClose={true}
      width={1000}
      okButtonProps={{
        className: "bg-admin-btn-primary",
      }}
      okText={props.data ? "Cập nhật" : "Tạo mới"}
      confirmLoading={loading}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Loại dịch vụ"
          name="dentalServicesId"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn loại dịch vụ muốn thêm mới!",
            },
          ]}
        >
          <Select
            placeholder="Chọn dịch vụ"
            style={{
              width: "100%",
            }}
            allowClear={true}
            popupMatchSelectWidth={false}
            filterOption={false}
          >
            {dataService?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Dịch vụ"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên dịch vụ!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Đơn vị tính"
              name="unit"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Giá thấp nhất"
              name="fromPrice"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập giá cho dịch vụ!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Giá cao nhất"
              name="toPrice"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Ghi chú giá thấp nhất"
              name="noteFromPrice"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Ghi chú giá cao nhất"
              name="noteToPrice"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Mô tả thêm hoặc bảo hành"
          name="note"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea
            showCount
            placeholder="Nhập thông tin mô tả dịch vụ của bạn"
            style={{
              height: 120,
              resize: "none",
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AddPrice;
