import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB2MmugFwAEfXSUCQOgcNDHjBWuLzNdj14",
  authDomain: "dental-store-5d467.firebaseapp.com",
  projectId: "dental-store-5d467",
  storageBucket: "dental-store-5d467.appspot.com",
  messagingSenderId: "474013997779",
  appId: "1:474013997779:web:a5f6e9120d9a69283c5226",
  measurementId: "G-QW6FL7SF55"
};

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const storage = getStorage(app);

export { firestore, storage };
