import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { NavLink, createSearchParams, useNavigate } from "react-router-dom";
import Div from "../div";
import { getAllService } from "../../api/service/api";

function ToggleNav(props) {
  const { isOpen, setIsOpen } = props;
  const [openService, setOpenService] = useState(false);
  const [servicesContent, setServiceContent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllService()
      .then((res) => {
        const data = res.data;

        data && setServiceContent(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const DropDown = ({ service }) => {
    return (
      <ul className={`mt-[10px] `}>
        {service.map((e, i) => (
          <li
            key={i}
            className="border-t border-white py-[10px] pl-[10px] text-[14px] last:pb-0"
          >
            <Div
              onClick={() => {
                navigate({
                  pathname: "/content-web",
                  search: `?${createSearchParams({
                    service: e.id,
                    name: e.name,
                  }).toString()}`,
                });
                setIsOpen(false);
              }}
            >
              {e.name}
            </Div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Div
      className={`fixed top-0 left-0 duration-700 w-full h-screen z-20 ${
        isOpen ? "bg-zinc-800/50" : "pointer-events-none"
      }`}
    >
      <nav
        className={`max-w-[260px] h-screen bg-p-color text-white relative pt-[55px] uppercase border-r shadow-[3px_0_15px_5px_rgba(100,100,100,0.3)] duration-700 ${
          isOpen ? "translate-x-0" : "translate-x-[-150%]"
        }`}
      >
        <span
          onClick={() => setIsOpen(false)}
          className="text-[25px] absolute top-0 right-0 p-[10px] cursor-pointer font-extrabold"
        >
          <IoClose />
        </span>

        <ul>
          <li className="border-b border-white py-[10px] pl-[10px] text-[14px]">
            <NavLink
              to={"/"}
              onClick={() => setIsOpen(false)}
              className={"block"}
            >
              Trang chủ
            </NavLink>
          </li>

          <li className="border-b border-white py-[10px] pl-[10px] text-[14px]">
            <NavLink
              to={"/about"}
              onClick={() => setIsOpen(false)}
              className={"block"}
            >
              Về chúng tôi
            </NavLink>
          </li>

          <li className="border-b border-white py-[10px] pl-[10px] text-[14px] duration-700">
            <p
              onClick={() => setOpenService(!openService)}
              className="flex justify-between pr-[10px]"
            >
              <NavLink
                to={"/service"}
                onClick={() => setIsOpen(false)}
                className={"basis-[50%]"}
              >
                <span>Dịch vụ</span>
              </NavLink>
              <span>{openService ? "-" : "+"}</span>
            </p>

            <Div
              className={`overflow-hidden duration-700 ${
                openService
                  ? `h-[250px]`
                  : "translate-y-[-10px] opacity-[0.25] h-0"
              }`}
            >
              <DropDown service={servicesContent} />
            </Div>
          </li>

          <li className="border-b border-white py-[10px] pl-[10px] text-[14px]">
            <NavLink
              to={"/service-price"}
              onClick={() => setIsOpen(false)}
              className={"block"}
            >
              Bảng giá
            </NavLink>
          </li>

          <li className="border-b border-white py-[10px] pl-[10px] text-[14px]">
            <NavLink
              to={"dental-product"}
              onClick={() => setIsOpen(false)}
              className={"block"}
            >
              Sản phẩm nha khoa
            </NavLink>
          </li>

          <li className="border-b border-white py-[10px] pl-[10px] text-[14px]">
            <NavLink
              to={"/contact"}
              onClick={() => setIsOpen(false)}
              className={"block"}
            >
              Liên hệ
            </NavLink>
          </li>
        </ul>
      </nav>
    </Div>
  );
}

export default ToggleNav;
