import React from "react";
import Div from "../../div";
import DoctorCarousel from "../../carousel/DoctorCarousel";

function DoctorList() {
  return (
    <Div>
      <Div className="my-container py-[40px]">
        <Div className="text-center">
          <h2 className={styles.title}>ĐỘI NGŨ BÁC SĨ</h2>
          <p className="lg:text-lg">
            Chuyên môn cao - Nhiều năm học tập và làm việc tại nước ngoài
          </p>
        </Div>

        <Div>
          <DoctorCarousel />
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  title: "uppercase text-3xl text-p-color font-bold mb-[15px]",
  readMoreBtn:
    "px-[15px] pt-[8px] pb-[10px] border-2 border-p-color rounded-full text-p-color font-semibold hover:text-white hover:bg-p-color duration-500 lg:px-[25px] lg:pt-[10px] lg:pb-[13px]",
};

export default DoctorList;
