import React from "react";
import Div from "../div";
import { Button, Result } from "antd";
import { useNavigate } from "react-router";

function NotFound() {
  const navigate = useNavigate();
  return (
    <Div className="w-screen h-screen bg-[#F2EEE8]">
      <Result
        status="404"
        title="404"
        subTitle="Trang bạn tìm kiếm không tồn tại"
        extra={
          <Button
            onClick={() => navigate("/")}
            className="bg-p-color"
            type="primary"
          >
            Quay về trang chủ
          </Button>
        }
      />
    </Div>
  );
}

export default NotFound;
