import React from "react";
import Div from "../../div";
import HistoryImg1 from "../../../asset/logo-and-service/DSC_8790.jpg";
import HistoryImg2 from "../../../asset/logo-and-service/history-1.jpg";

function History() {
  return (
    <Div>
      <Div className="my-container py-[30px] lg:py-[80px]">
        <Div>
          <h1 className={styles.aboutPageTitle}>LỊCH SỬ HÌNH THÀNH</h1>
        </Div>

        <Div className="text-center py-[20px] font-semibold">
          <p className="mb-[10px] text-xl">Thành lập vào tháng 8 năm 2016</p>
          <p className="text-2xl text-p-color underline">
            Với những thành viên trụ cột của Nha Khoa Phấn Hoa
          </p>
        </Div>

        <Div className="flex flex-col md:grid grid-cols-12 gap-[50px] py-[20px]">
          <Div className="col-span-12 md:col-span-6 group">
            <Div className="w-full h-[350px] overflow-hidden">
              <img className={styles.images} src={HistoryImg1} alt="" />
            </Div>

            <Div className="text-white font font-semibold bg-linear-color p-[20px] text-center">
              <p className="text-3xl mb-[8px]">Vũ Văn Phấn</p>
              <p className="text-xl">Bác Sĩ Chuyên Khoa Răng Hàm Mặt</p>
            </Div>
          </Div>

          <Div className="col-span-12 md:col-span-6 group">
            <Div className="w-full h-[350px] overflow-hidden">
              <img className={styles.images} src={HistoryImg2} alt="" />
            </Div>

            <Div className="text-white font font-semibold bg-linear-color p-[20px] text-center">
              <p className="text-3xl mb-[8px]">Bùi Thị Hoa</p>
              <p className="text-xl">Giám Đốc Kinh Doanh</p>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  aboutPageTitle:
    "uppercase text-p-color text-2xl font-bold text-center md:text-3xl lg:text-4xl",
  images:
    "w-full h-full object-cover object-center group-hover:scale-105 duration-[2s]",
};

export default History;
