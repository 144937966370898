import React from "react";
import Div from "../div";
import { GrNext, GrPrevious } from "react-icons/gr";
import { IoSearch } from "react-icons/io5";
import Slider from "react-slick";
import "./reactSlickStyle.css";
import { Link } from "react-router-dom";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <Div
      onClick={onClick}
      className="absolute right-[-10px] top-[46%] text-white bg-p-color py-[8px] pl-[10px] pr-[8px] rounded-full z-10 cursor-pointer lg:text-2xl lg:right-[-60px]"
    >
      <GrNext />
    </Div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <Div
      onClick={onClick}
      className="absolute left-[-10px] top-[46%] text-white bg-p-color py-[8px] pl-[8px] pr-[10px] rounded-full z-10 cursor-pointer lg:text-2xl lg:left-[-60px]"
    >
      <GrPrevious />
    </Div>
  );
};

function ClinicCarousel({ listClinic, setImageIndex, setShowImage }) {
  const settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "center",
    rows: 2,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Div className="my-container">
      <Slider {...settings}>
        {listClinic.map((e, i) => {
          return (
            <Div key={i}>
              <Link
                to={""}
                className="block h-[210px] w-full relative lg:h-[280px] group"
              >
                <img
                  key={i}
                  src={e.img}
                  alt=""
                  className="w-full h-full my-[10px] object-cover object-bottom"
                />

                <Div
                  onClick={() => {
                    setImageIndex(i);
                    setShowImage(true);
                  }}
                  className={styles.hoverBg}
                ></Div>

                <Div className={styles.hoverIcon}>
                  <IoSearch />
                </Div>
              </Link>
            </Div>
          );
        })}
      </Slider>
    </Div>
  );
}

const styles = {
  hoverBg:
    "absolute w-full h-full bg-linear-color top-0 left-[50%] translate-x-[-50%] invisible opacity-0 group-hover:visible group-hover:opacity-100 duration-500",
  hoverIcon:
    "absolute top-[45%] left-[50%] translate-x-[-50%] text-white text-4xl translate-y-[30px] opacity-0 invisible group-hover:translate-y-0 group-hover:opacity-100 group-hover:visible duration-500",
};

export default ClinicCarousel;
