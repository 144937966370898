import React, { useEffect, useState } from "react";
import Div from "../../div";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { getAllContentWeb } from "../../../api/service/api-content";

function ContentWebContact() {
  const [servicesContent, setServiceContent] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllContentWeb()
      .then((res) => {
        const data = res.data;
        let filterData = [];

        data &&
          data.forEach((e, i) => {
            if (
              e.active === 1 &&
              !filterData.find(
                (item) => item.dentalServicesId === e.dentalServicesId
              )
            ) {
              filterData = [
                ...filterData,
                {
                  dentalServicesId: e.dentalServicesId,
                  dentalServicesName: e.dentalServicesName,
                },
              ];
            }
          });

        setServiceContent(filterData);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Div>
      <Div className="my-container pb-[50px]">
        <Div>
          <h2 className="text-2xl text-p-color font-bold mb-[10px]">
            Nha khoa Phấn Hoa
          </h2>

          <Div>
            <ul className="flex flex-col gap-[8px]">
              <li>
                <p className="text-base">
                  <span>Địa chỉ cơ sở 1: </span>
                  <Link
                    to={
                      "https://www.google.com/maps/place/Nha+khoa+Ph%E1%BA%A5n+Hoa/@20.9183911,106.7842468,14z/data=!4m10!1m2!2m1!1snha+khoa+ph%E1%BA%A5n+hoa!3m6!1s0x314a6358e85a4ecf:0xf370a5f3e28f20ac!8m2!3d20.9388027!4d106.8012418!15sChNuaGEga2hvYSBwaOG6pW4gaG9hkgENZGVudGFsX2NsaW5pY-ABAA!16s%2Fg%2F11td8bglmh?hl=vi-VN&entry=ttu"
                    }
                    target="_blank"
                    className="text-base text-p-color"
                  >
                    76 - Khu 5 - Phường Phong Cốc - Thị xã Quảng Yên - Tỉnh
                    Quảng Ninh
                  </Link>
                </p>
              </li>

              <li>
                <p className="text-base">
                  <span>Địa chỉ cơ sở 2: </span>
                  <Link
                    to={
                      "https://www.google.com/maps/place/Nha+khoa+Ph%E1%BA%A5n+Hoa/@20.9183911,106.7842468,14z/data=!4m10!1m2!2m1!1snha+khoa+ph%E1%BA%A5n+hoa!3m6!1s0x314a6358e85a4ecf:0xf370a5f3e28f20ac!8m2!3d20.9388027!4d106.8012418!15sChNuaGEga2hvYSBwaOG6pW4gaG9hkgENZGVudGFsX2NsaW5pY-ABAA!16s%2Fg%2F11td8bglmh?hl=vi-VN&entry=ttu"
                    }
                    target="_blank"
                    className="text-base text-p-color"
                  >
                    42 Lê Lợi - Thị Xã Quảng Yên - Tỉnh Quảng Ninh
                  </Link>
                </p>
              </li>

              <li>
                <p className="text-base">
                  <span>Địa chỉ cơ sở 3: </span>
                  <Link
                    to={
                      "https://www.google.com/maps/place/Nha+khoa+Ph%E1%BA%A5n+Hoa/@20.9183911,106.7842468,14z/data=!4m10!1m2!2m1!1snha+khoa+ph%E1%BA%A5n+hoa!3m6!1s0x314a6358e85a4ecf:0xf370a5f3e28f20ac!8m2!3d20.9388027!4d106.8012418!15sChNuaGEga2hvYSBwaOG6pW4gaG9hkgENZGVudGFsX2NsaW5pY-ABAA!16s%2Fg%2F11td8bglmh?hl=vi-VN&entry=ttu"
                    }
                    target="_blank"
                    className="text-base text-p-color"
                  >
                    Ngã tư Loong Toòng - Khu liền kề 02: 14-15 - Phường Yết Kiêu
                    - TP.Hạ Long - Tỉnh Quảng Ninh
                  </Link>
                </p>
              </li>

              <li>
                <p className="text-base">
                  <span>Hotline: </span>
                  <span>0971 759 666</span>
                </p>
              </li>

              <li>
                <p className="text-base">
                  <span>Website chính thức: </span>
                  <Link to={"/"} className="text-base text-p-color">
                    nhakhoaphanhoa.com.vn
                  </Link>
                </p>
              </li>

              <li>
                <p className="text-base">
                  <span>Facebook: </span>
                  <Link
                    to={
                      "https://www.facebook.com/profile.php?id=100090911509880"
                    }
                    target="_blank"
                    className="text-base text-p-color"
                  >
                    Nha khoa Phấn hoa
                  </Link>
                </p>
              </li>

              <li>
                <p className="text-base">
                  <span>Chat trực tiếp với bác sĩ chuyên khoa: </span>
                  <Link
                    to={"https://www.m.me/100090911509880"}
                    target="_blank"
                    className="text-base text-p-color"
                  >
                    facebook
                  </Link>
                </p>
              </li>

              {servicesContent.map((e, i) => {
                return (
                  <li key={i}>
                    <Div
                      onClick={() =>
                        navigate({
                          pathname: "/content-web",
                          search: `?${createSearchParams({
                            service: e.dentalServicesId,
                            name: e.dentalServicesName,
                          }).toString()}`,
                        })
                      }
                      className="text-base text-p-color cursor-pointer"
                    >
                      Tìm hiểu thêm về dịch vụ {e.dentalServicesName}
                    </Div>
                  </li>
                );
              })}
            </ul>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

export default ContentWebContact;
