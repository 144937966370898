import React, { useEffect } from "react";
import NavigateBanner from "../../common/navigate-banner";
import Product from "./Product";

function DentalProduct() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <section>
        <NavigateBanner navigatePage={"Sản phẩm nha khoa"} />
      </section>

      <section>
        <Product />
      </section>
    </main>
  );
}

export default DentalProduct;
