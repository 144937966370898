import { Form, Input, message, Modal } from "antd";
import { useEffect, useState } from "react";
import UploadImage from "../common/UploadImage";
import TextArea from "antd/es/input/TextArea";
import CkEditorCustome from "../common/CkEditorCustom";
import {
  postCustomerDocument,
  updateCustomerDocument,
} from "../../../api/customer-documents/api";

const AddCustomerDocument = (props) => {
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(props?.data?.description);

  useEffect(() => {
    if (props.data && props.isVisible) {
      form.setFieldsValue(props.data);
    } else {
      form.resetFields();
    }
  }, [form, props.data, props.isVisible]);

  const onFinish = (values) => {
    setLoading(true);
    const value = { ...values, description: content, images: images };
    if (props.data) {
      updateCustomerDocument(props.data.id, value)
        .then((res) => {
          message.success("Sửa khách hàng thành công");
          form.resetFields();
          props.onCancel();
        })
        .finally(() => setLoading(false));
    } else {
      postCustomerDocument(value)
        .then((res) => {
          message.success("Thêm khách hàng thành công");
          form.resetFields();
          props.onCancel();
        })
        .finally(() => setLoading(false));
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
  };

  return (
    <Modal
      title={props.data ? "Sửa khách hàng" : "Thêm mới khách hàng"}
      centered
      open={props.isVisible}
      onOk={() => form.submit()}
      onCancel={props.onCancel}
      width={1000}
      destroyOnClose={true}
      okButtonProps={{
        className: "bg-admin-btn-primary",
      }}
      okText={props.data ? "Cập nhật" : "Tạo mới"}
      confirmLoading={loading}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Khách hàng"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập khách hàng!",
            },
          ]}
        >
          <Input placeholder={"Nhập khách hàng"} />
        </Form.Item>
        <Form.Item
          label="Nha khoa"
          name="supplier"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập nha khoa!",
            },
          ]}
        >
          <Input placeholder={"Nhập nha khoa"} />
        </Form.Item>
        <Form.Item
          label="Mô tả ngắn"
          name="type"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea
            showCount
            placeholder="Nhập thông tin mô tả"
            style={{
              height: 120,
              resize: "none",
            }}
          />
        </Form.Item>
        <CkEditorCustome
          label={"Nội dung khách hàng"}
          description={props?.data?.description}
          setContent={handleChange}
        />
        <UploadImage url={(e) => setImages(e)} />
      </Form>
    </Modal>
  );
};
export default AddCustomerDocument;
