import {
  Button,
  Col,
  Empty,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import { columnPrice } from "../common/columns";
import { useEffect, useState } from "react";
import AddPrice from "./content/add";
import {
  deletePriceService,
  getAllPriceService,
} from "../../../api/price-service/api";
import dayjs from "dayjs";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const PriceService = () => {
  const locale = {
    emptyText: (
      <Space direction="vertical" align="center" style={{ marginTop: "20px" }}>
        <Empty description={"Chưa có dữ liệu bảng giá"} />
      </Space>
    ),
  };
  const [isVisible, setIsvisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (!isVisible) getData();
  }, [isVisible]);

  function getData() {
    setLoading(true);
    getAllPriceService()
      .then((res) => {
        setDataSource(mapDataSource(res.data));
      })
      .finally(() => setLoading(false));
  }

  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
    minimumFractionDigits: 0,
  });

  const mapDataSource = (values) => {
    return values?.map((item) => {
      const newItem = {
        ...item,
        key: item.id,
        createDate: dayjs(item.createdAt).format("DD-MM-YYYY"),
        serviceGroup: item?.dentalServicesName,
        service: item?.name,
        price:
          formatter.format(item.fromPrice) +
          " - " +
          formatter.format(item.toPrice),
        note: item.note,
        menu: (
          <>
            <Row gutter={12} justify={"end"} align={"middle"}>
              <Col>
                <EditOutlined
                  onClick={() => {
                    setData(item);
                    setIsvisible(true);
                  }}
                />
              </Col>
              <Col>
                <DeleteOutlined onClick={() => confirmDelete(item.id)} />
              </Col>
            </Row>
          </>
        ),
      };

      if (!item.toPrice > 0) {
        newItem.price = formatter.format(item.fromPrice);
      }

      return newItem;
    });
  };

  function confirmDelete(id) {
    Modal.confirm({
      title: "Xác nhận",
      content: "Bạn có chắc là muốn xóa bảng giá này không?",
      okText: <div style={{ color: "white" }}>Xóa</div>,
      okButtonProps: {
        className: "bg-admin-btn-primary",
      },
      onOk: () => {
        deletePriceService(id).then((res) => {
          message.success("Xóa bảng giá thành công");
          getData();
        });
      },
    });
  }

  return (
    <>
      <div style={{ padding: "10px" }}>
        <Button type="dashed" onClick={() => setIsvisible(true)}>
          Tạo mới giá
        </Button>
      </div>
      <Spin tip="Xin vui lòng chờ..." spinning={loading}>
        <Table columns={columnPrice} dataSource={dataSource} locale={locale} />
      </Spin>
      <AddPrice
        isVisible={isVisible}
        onCancel={() => {
          setIsvisible(false);
          setData(null);
        }}
        data={data}
      />
    </>
  );
};
export default PriceService;
