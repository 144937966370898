import React, { useEffect, useState } from "react";
import NavigateBanner from "../../common/navigate-banner";
import PriceTable from "./PriceTable";
import { getAllPriceService } from "../../../api/price-service/api";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Div from "../../div";

function ServicePrice() {
  const [servicesPrice, setServicesPrice] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const result = {};

    getAllPriceService()
      .then((res) => {
        const data = res.data;
        setIsLoading(true);

        data?.forEach((e) => {
          if (!result[e.dentalServicesId]) {
            result[e.dentalServicesId] = [];
          }
          result[e.dentalServicesId] = [...result[e.dentalServicesId], e];
        });

        data && setServicesPrice(result);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <main>
      <section>
        <NavigateBanner navigatePage={"Bảng giá"} />
      </section>

      <section>
        {isLoading ? (
          <Div className="h-[300px] flex items-center justify-center">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
            />
          </Div>
        ) : (
          <PriceTable servicePrice={servicesPrice} />
        )}
      </section>
    </main>
  );
}

export default ServicePrice;
