import axios from "axios";

const BASE_URL_SERVICE = `${process.env.REACT_APP_DOMAIN_API}api/dental-services`;

export async function getAllService() {
    const response = await axios.get(BASE_URL_SERVICE);
    return response?.data || [];
}

export async function getService(id) {
    const response = await axios.get(`${BASE_URL_SERVICE}/${id}`);
    return response?.data || {};
}

export async function postService(service) {
    const response = await axios.post(BASE_URL_SERVICE, service);
    return response?.data || {};
}

export async function updateService(id, service) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/update`, service);
    return response?.data || {};
}

export async function deleteService(id) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/delete`);
    return response?.data || {};
}
