import React, { useRef, useState } from "react";
import Div from "../../div";
import ReCAPTCHA from "react-google-recaptcha";
import { message } from "antd";
import { createContact } from "../../../api/contacts/api";
import BottomAdviceBackground from "../../../asset/background/bottom-advice-bg.jpg";

function BottomAdviseForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [tokenCapcha, setTokenCapcha] = useState();
  const [values, setValues] = useState({
    userName: "",
    phoneNumber: "",
    email: "",
    name: "general",
    content: "",
  });

  const recaptchaRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const emailRegex = /^([\w\.\+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/;
  const phoneRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!values.userName || !values.phoneNumber || !values.email) {
      message.error("Vui lòng điền đầy đủ thông tin để được tư vấn!");
      setIsLoading(false);
      return;
    }

    if (
      !phoneRegex.test(values.phoneNumber) ||
      !emailRegex.test(values.email)
    ) {
      message.error("Số điện thoại hoặc email không hợp lệ vui lòng xem lại!");
      setIsLoading(false);
      return;
    }

    if (!tokenCapcha) {
      message.error("Vui lòng xác thực lại!");
      setIsLoading(false);
      return;
    }

    const value = { ...values, recaptchaValue: tokenCapcha, type: 6 };

    createContact(value)
      .then(() => {
        message.success("Đăng ký tư vấn thành công");
        recaptchaRef.current.reset();
        setValues({
          userName: "",
          phoneNumber: "",
          email: "",
          name: "general",
          content: "",
        });
      })
      .catch(() => {
        message.error("Đăng ký thất bại!");
      })
      .finally(() => {
        setIsLoading(false);
        setTokenCapcha("");
      });
  };

  function onChange(value) {
    setTokenCapcha(value);
  }
  return (
    <Div>
      <Div
        style={{
          backgroundImage: `linear-gradient(#ffffff99,#ffffff99),url(${BottomAdviceBackground})`,
        }}
        className={styles.sectionBg}
      >
        <Div className={styles.container}>
          <Div className="font-bold mb-[40px] md:basis-[50%]">
            <p className="text-[22px] md:text-[28px] lg:text-[38px]">
              Đặt lịch ngay hôm nay
            </p>
            <p className="uppercase text-[26px] md:text-[35px] lg:text-[45px]">
              NHẬN ƯU ĐÃI
            </p>
            <p className="uppercase text-p-color text-[26px] md:text-[35px] lg:text-[45px]">
              LIỀN TAY
            </p>
            <p className="md:text-[25px] lg:text-[33px]">Dành cho bạn</p>
          </Div>

          <Div className="md:basis-[50%] lg:basis-[45%]">
            <Div className={styles.formBox}>
              <h2 className={styles.formTitle}>ĐĂNG KÝ TƯ VẤN MIỄN PHÍ</h2>

              <p className="font-bold text-sm text-black/50 pb-[10px] lg:text-base">
                Đăng ký thông tin cá nhân để được chuyên viên tư vấn miễn phí.
                Mọi thông tin khách hàng cung cấp đều được chúng tôi bảo mật.
              </p>

              <form
                onSubmit={(e) => handleSubmit(e)}
                className="font-bold text-sm text-black/50 pb-[10px]"
              >
                <Div className="mb-[8px] relative">
                  <input
                    type="text"
                    placeholder="Họ và tên"
                    name="userName"
                    value={values.userName}
                    onChange={(e) => handleChange(e)}
                    className={styles.textInput}
                  />
                </Div>

                <Div className="mb-[8px] relative">
                  <input
                    type="text"
                    placeholder="Số điện thoại"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={(e) => handleChange(e)}
                    className={styles.textInput}
                  />
                </Div>

                <Div className="mb-[8px] relative">
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={(e) => handleChange(e)}
                    className={styles.textInput}
                  />
                </Div>

                <Div className="mb-[8px] relative">
                  <p className="mt-[20px] mb-[10px] lg:text-lg">
                    Dịch vụ đăng ký
                  </p>

                  <Div className="flex flex-wrap flex-col gap-[10px] lg:flex-row">
                    <Div className="flex items-start gap-1 lg:w-[45%]">
                      <input
                        id="bottom-general"
                        type="radio"
                        value={"general"}
                        onChange={(e) => handleChange(e)}
                        checked={values.name === "general"}
                        className={styles.radioInput}
                        name="name"
                      />
                      <label htmlFor="bottom-general">
                        Khám nha khoa tổng quát
                      </label>
                    </Div>

                    <Div className="flex items-start gap-1 lg:w-[45%]">
                      <input
                        id="bottom-tooth-extraction"
                        type="radio"
                        value={"tooth-extraction"}
                        onChange={(e) => handleChange(e)}
                        checked={values.name === "tooth-extraction"}
                        className={styles.radioInput}
                        name="name"
                      />
                      <label htmlFor="bottom-tooth-extraction">
                        Nhổ răng, tiểu phẫu
                      </label>
                    </Div>

                    <Div className="flex items-start gap-1 lg:w-[45%]">
                      <input
                        id="bottom-orthodontics"
                        type="radio"
                        value={"orthodontics"}
                        onChange={(e) => handleChange(e)}
                        checked={values.name === "orthodontics"}
                        className={styles.radioInput}
                        name="name"
                      />
                      <label htmlFor="bottom-orthodontics">
                        Chỉnh nha, niềng răng
                      </label>
                    </Div>
                    <Div className="flex items-start gap-1 w-[45%]">
                      <input
                        id="other"
                        type="radio"
                        value="implants"
                        checked={values.name === "implants"}
                        className={styles.radioInput}
                        onChange={(e) => handleChange(e)}
                        name="name"
                      />
                      <label htmlFor="other">Cấy ghép implant</label>
                    </Div>
                    <Div className="flex items-start gap-1 w-[45%]">
                      <input
                        id="other"
                        type="radio"
                        value="porcelain-teeth"
                        checked={values.name === "porcelain-teeth"}
                        className={styles.radioInput}
                        onChange={(e) => handleChange(e)}
                        name="name"
                      />
                      <label htmlFor="other">Răng sứ thẩm mỹ</label>
                    </Div>
                    <Div className="flex items-start gap-1 lg:w-[45%]">
                      <input
                        id="bottom-other-service"
                        type="radio"
                        value={"other-service"}
                        onChange={(e) => handleChange(e)}
                        checked={values.name === "other-service"}
                        className={styles.radioInput}
                        name="name"
                      />
                      <label htmlFor="bottom-other-service">Dịch vụ khác</label>
                    </Div>
                  </Div>
                </Div>

                <Div className="mt-[20px] mb-[10px] relative">
                  <textarea
                    name="content"
                    value={values.content}
                    onChange={(e) => handleChange(e)}
                    placeholder="Nội dung yêu cầu"
                    className={styles.textarea}
                  ></textarea>
                </Div>

                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY_CAPCHA}
                  onChange={onChange}
                  ref={recaptchaRef}
                />
                <Div className="mt-[30px]">
                  <button
                    type="submit"
                    className={styles.submitBtn}
                    disabled={isLoading}
                  >
                    Đăng ký
                  </button>
                </Div>
              </form>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  sectionBg: "bg-center bg-cover bg-no-repeat",
  container:
    "my-container py-[30px] flex flex-col md:flex-row md:items-center justify-between lg:pt-[100px] lg:pb-[40px]",
  formBox:
    "px-[15px] py-[30px] bg-white rounded-lg shadow-[0_0_10px_8px_rgba(0,0,0,0.3)] lg:px-[35px]",
  formTitle:
    "text-[20px] mb-[20px] text-p-color font-bold uppercase relative before:content-[''] before:absolute before:w-[80px] before:h-[3px] before:bottom-[-8px] before:left-[8px] before:mx-auto before:bg-p-color md:text-[25px] lg:text-[25px] lg:before:left-0 lg:before:w-[123px]",
  errorMsg:
    "relative top-[2px] left-0 pt-[2px] pb-[5px] px-[10px] bg-p-color text-white font-normal z-10 before:content-[''] before:bg-p-color before:w-[10px] before:h-[10px] before:absolute before:top-[-5px] before:left-[50%] before:translate-x-[-50%] before:rotate-45 before:-z-10",
  recaptchaError:
    "inline-block relative w-full text-center top-[2px] left-[50%] translate-x-[-50%] pt-[2px] pb-[5px] bg-p-color text-white font-normal z-10 before:content-[''] before:bg-p-color before:w-[10px] before:h-[10px] before:absolute before:top-[-5px] before:left-[50%] before:translate-x-[-50%] before:rotate-45",
  textInput:
    "border border-black/40 w-full px-[10px] py-[7px] lg:py-[10px] rounded-lg outline-none",
  radioInput: "inline-block mt-[3px]",
  submitBtn:
    "px-[20px] pt-[6px] pb-[9px] rounded-full text-lg border-2 border-p-color text-p-color duration-500 hover:text-white hover:bg-p-color lg:px-[30px]",
  textarea:
    "resize-none w-full border border-black/40 p-[10px] rounded-lg outline-none",
};

export default BottomAdviseForm;
