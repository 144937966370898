import React from "react";
import Div from "../../div";
import UniteImage from "../../../asset/logo-and-service/Doan-Ket.jpg";
import CultureImage from "../../../asset/logo-and-service/culture-image.jpg";
import {
  creative,
  prestige,
  solidarity,
} from "../../common/mate-data/mateData";

function Culture() {
  return (
    <Div>
      <Div className="my-container py-[30px] lg:py-[80px]">
        <Div>
          <h1 className={styles.aboutPageTitle}>TRỤ CỘT VĂN HOÁ</h1>
        </Div>

        <article>
          <Div className="lg:flex">
            <Div className="lg:basis-[65%]">
              <Div
                className={`${styles.sectionBox} mt-[20px] lg:mt-[40px] after:bottom-[-12.5px] after:bg-p-color`}
              >
                <h2 className={styles.sectionTitle}>UY TÍN - DẪN ĐẦU</h2>

                <ul className=" duration-300 group-hover:text-white">
                  {prestige.map((e, i) => {
                    return (
                      <li key={i} className="mb-[10px]">
                        <p className="text-sm md:text-base lg:text-lg">
                          <span className="font-semibold">{e.title}: </span>
                          {e.content}
                        </p>
                      </li>
                    );
                  })}
                </ul>

                <Div className={`${styles.slideBg} bottom-0`}></Div>
              </Div>

              <Div className="w-full h-[629px] mt-[20px] md:mt-0">
                <img className={styles.image} src={CultureImage} alt="" />
              </Div>
            </Div>

            <Div className="lg:basis-[35%]">
              <Div
                className={`${styles.sectionBox} mt-[50px] after:bottom-[-12.5px] after:bg-p-color`}
              >
                <Div>
                  <h2 className={styles.sectionTitle}>
                    ĐOÀN KẾT - TRÁCH NHIỆM
                  </h2>
                </Div>

                <ul className="pl-[10px] text-sm duration-300 group-hover:text-white md:text-base lg:text-lg">
                  {solidarity.map((e, i) => {
                    return (
                      <li key={i} className="mb-[10px]">
                        <p className="text-sm md:text-base lg:text-lg">
                          <span className="font-semibold">{e.title}: </span>
                          {e.content}
                        </p>
                      </li>
                    );
                  })}
                </ul>

                <Div className={`${styles.slideBg} bottom-0`}></Div>
              </Div>

              <Div className="w-full h-[280px] mt-[30px] md:mt-0">
                <img className={styles.image} src={UniteImage} alt="" />
              </Div>

              <Div>
                <Div
                  className={`${styles.sectionBox} mt-[40px] after:top-[-12.5px] after:bg-white lg:mt-[20px]`}
                >
                  <Div>
                    <h2 className={styles.sectionTitle}>SÁNG TẠO</h2>
                  </Div>

                  <ul className="ml-[10px] text-sm duration-300 group-hover:text-white md:text-base lg:text-lg">
                    {creative.map((e, i) => {
                      return (
                        <li key={i} className="mb-[5px] last:mb-0">
                          {e.content}
                        </li>
                      );
                    })}
                  </ul>

                  <Div className={`${styles.slideBg} top-0`}></Div>
                </Div>
              </Div>
            </Div>
          </Div>
        </article>
      </Div>
    </Div>
  );
}

const styles = {
  aboutPageTitle:
    "uppercase text-p-color text-2xl font-bold text-center md:text-3xl lg:text-4xl",
  sectionBox:
    "overflow-hidden relative group p-[10px] md:px-[30px] md:py-[70px] after:hidden md:after:block after:content-[''] after:absolute after:left-[50%] after:translate-x-[-50%] after:rotate-[45deg] after:w-[25px] after:h-[25px] hover:after:bg-white after:duration-500",
  sectionTitle:
    "uppercase text-p-color text-xl font-bold text-center mb-[20px] duration-300 group-hover:text-white md:text-2xl",
  slideBg:
    "w-full h-0 absolute left-[50%] translate-x-[-50%] bottom-0 bg-p-color -z-10 duration-300 group-hover:h-full",
  image: "w-full h-full object-cover object-center",
};

export default Culture;
