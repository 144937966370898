import React, { useEffect, useRef, useState } from "react";
import Div from "../div/index";
import { IoCloseCircle } from "react-icons/io5";
import ReCAPTCHA from "react-google-recaptcha";
import { message } from "antd";
import { createContact } from "../../api/contacts/api";
import AdviseImg from "../../asset/logo-and-service/advise.jpg";

function ModalAdvise({ isOpen }) {
  const [isLoading, setIsLoading] = useState(false);
  const [tokenCapcha, setTokenCapcha] = useState();
  const [values, setValues] = useState({
    userName: "",
    phoneNumber: "",
    email: "",
    name: "general",
    content: "",
  });
  const recaptchaRef = useRef();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const emailRegex = /^([\w.+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/;
  const phoneRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      values.fullName === "" ||
      values.phoneNumber === "" ||
      values.email === ""
    ) {
      message.error("Vui lòng điền đầy đủ thông tin để được tư vấn!");
      setIsLoading(false);
      return;
    }

    if (
      !phoneRegex.test(values.phoneNumber) ||
      !emailRegex.test(values.email)
    ) {
      message.error("Số điện thoại hoặc email không hợp lệ vui lòng xem lại!");
      setIsLoading(false);
      return;
    }

    if (!tokenCapcha) {
      message.error("Vui lòng xác thực lại!");
      setIsLoading(false);
      return;
    }

    const value = { ...values, recaptchaValue: tokenCapcha, type: 6 };
    createContact(value)
      .then(() => {
        message.success("Đăng ký tư vấn thành công");
        isOpen.setOpenAdviseModal(false);
        recaptchaRef.current.reset();
        setValues({
          userName: "",
          phoneNumber: "",
          email: "",
          name: "general",
          content: "",
        });
      })
      .catch(() => {
        message.error("Đăng ký thất bại!");
      })
      .finally(() => {
        setIsLoading(false);
        setTokenCapcha("")});
  };

  useEffect(() => {
    if (isOpen) {
      setValues({
        userName: "",
        phoneNumber: "",
        email: "",
        name: "general",
        content: "",
      });
    }
  }, [isOpen]);

  function onChange(value) {
    setTokenCapcha(value);
  }
  return (
    <Div
      className={`${styles.container} ${
        isOpen.openAdviseModal ? "visible opacity-100" : "invisible opacity-0"
      }`}
    >
      <Div className={styles.modalBox}>
        <Div
          onClick={() => isOpen.setOpenAdviseModal(false)}
          className={styles.closeBtn}
        >
          <IoCloseCircle />
        </Div>

        <Div className={styles.formBox}>
          <Div className="hidden md:block lg:basis-[1100px] lg:pr-[15px]">
            <img
              className="w-full object-cover object-center"
              src={AdviseImg}
              alt=""
            />
          </Div>

          <Div className="px-[15px]">
            <h2 className={styles.formTitle}>ĐĂNG KÝ TƯ VẤN MIỄN PHÍ</h2>

            <p className="text-center font-bold text-sm text-black/50 pb-[10px]">
              Đăng ký thông tin cá nhân để được chuyên viên tư vấn miễn phí. Mọi
              thông tin khách hàng cung cấp đều được chúng tôi bảo mật.
            </p>

            <form
              onSubmit={handleSubmit}
              className="font-bold text-sm text-black/50 pb-[10px]"
            >
              <Div className="mb-[8px] relative">
                <input
                  type="text"
                  placeholder="Họ và tên"
                  name="userName"
                  value={values.userName}
                  className={styles.textInput}
                  onChange={(e) => handleChange(e)}
                />
              </Div>
              <Div className="mb-[8px] relative">
                <input
                  type="text"
                  placeholder="Số điện thoại"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  className={styles.textInput}
                  onChange={(e) => handleChange(e)}
                />
              </Div>
              <Div className="mb-[8px] relative">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  className={styles.textInput}
                  onChange={(e) => handleChange(e)}
                />
              </Div>
              <Div className="mb-[8px] relative">
                <p className="mt-[20px] mb-[10px]">Dịch vụ đăng ký</p>

                <Div className="flex flex-wrap">
                  <Div className="flex items-start gap-1 w-[45%]">
                    <input
                      id="general"
                      type="radio"
                      value="general"
                      checked={values.name === "general"}
                      className={styles.radioInput}
                      onChange={(e) => handleChange(e)}
                      name="name"
                    />
                    <label htmlFor="general">Khám nha khoa tổng quát</label>
                  </Div>

                  <Div className="flex items-start gap-1 w-[45%]">
                    <input
                      id="tooth-extraction"
                      type="radio"
                      value="tooth-extraction"
                      checked={values.name === "tooth-extraction"}
                      className={styles.radioInput}
                      onChange={(e) => handleChange(e)}
                      name="name"
                    />
                    <label htmlFor="tooth-extraction">
                      Nhổ răng, tiểu phẫu
                    </label>
                  </Div>

                  <Div className="flex items-start gap-1 w-[45%]">
                    <input
                      id="orthodontics"
                      type="radio"
                      value="orthodontics"
                      checked={values.name === "orthodontics"}
                      className={styles.radioInput}
                      onChange={(e) => handleChange(e)}
                      name="name"
                    />
                    <label htmlFor="orthodontics">Chỉnh nha, niềng răng</label>
                  </Div>

                  <Div className="flex items-start gap-1 w-[45%]">
                    <input
                      id="other"
                      type="radio"
                      value="implants"
                      checked={values.name === "implants"}
                      className={styles.radioInput}
                      onChange={(e) => handleChange(e)}
                      name="name"
                    />
                    <label htmlFor="other">Cấy ghép implant</label>
                  </Div>
                  <Div className="flex items-start gap-1 w-[45%]">
                    <input
                      id="other"
                      type="radio"
                      value="porcelain-teeth"
                      checked={values.name === "porcelain-teeth"}
                      className={styles.radioInput}
                      onChange={(e) => handleChange(e)}
                      name="name"
                    />
                    <label htmlFor="other">Răng sứ thẩm mỹ</label>
                  </Div>
                  <Div className="flex items-start gap-1 w-[45%]">
                    <input
                      id="other"
                      type="radio"
                      value="other-service"
                      checked={values.name === "other-service"}
                      className={styles.radioInput}
                      onChange={(e) => handleChange(e)}
                      name="name"
                    />
                    <label htmlFor="other">Dịch vụ khác</label>
                  </Div>
                </Div>
              </Div>
              <Div className="mt-[20px] mb-[10px] relative">
                <textarea
                  name="content"
                  value={values.content}
                  placeholder="Nội dung yêu cầu"
                  className={styles.textarea}
                  onChange={(e) => handleChange(e)}
                ></textarea>
              </Div>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY_CAPCHA}
                onChange={onChange}
                ref={recaptchaRef}
              />
              <Div className="text-center my-[15px]">
                <button
                    type="submit"
                    className={styles.submitBtn}
                    disabled={isLoading}
                  >
                    {isLoading ? "Đang gửi..." : "Đăng ký"}
                  </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  container:
    "fixed px-[10px] md:px-[20px] top-0 left-[50%] translate-x-[-50%] w-full h-full bg-linear-color z-50 overflow-auto no-scroll duration-700 lg:px-0",
  formBox:
    "w-full h-full py-[30px] px-[8px] md:p-[30px] flex flex-col lg:flex-row md:gap-[50px] lg:gap-0",
  formTitle:
    "text-center text-[25px] md:text-[28px] mb-[20px] text-p-color font-bold uppercase relative before:content-[''] before:absolute before:w-[80px] before:h-[3px] before:bottom-[-8px] before:left-0 before:right-0 before:mx-auto before:bg-p-color",
  modalBox: "max-w-[1024px] mx-auto my-[40px] bg-white rounded-lg relative",
  closeBtn:
    "absolute top-[-12px] right-0 text-4xl bg-white rounded-full cursor-pointer",
  errorMsg:
    "relative top-[2px] left-0 pt-[2px] pb-[5px] px-[10px] bg-p-color text-white font-normal z-10 before:content-[''] before:bg-p-color before:w-[10px] before:h-[10px] before:absolute before:top-[-5px] before:left-[50%] before:translate-x-[-50%] before:rotate-45 before:-z-10",
  recaptchaError:
    "inline-block relative w-full text-center top-[2px] left-[50%] translate-x-[-50%] pt-[2px] pb-[5px] bg-p-color text-white font-normal z-10 before:content-[''] before:bg-p-color before:w-[10px] before:h-[10px] before:absolute before:top-[-5px] before:left-[50%] before:translate-x-[-50%] before:rotate-45",
  textInput: "border border-black/40 w-full p-[10px] rounded-lg outline-none",
  radioInput: "inline-block mt-[3px]",
  submitBtn: "px-[50px] py-[15px] bg-p-color rounded-full text-lg text-white",
  textarea:
    "resize-none w-full border border-black/40 p-[10px] rounded-lg outline-none",
};

export default ModalAdvise;
