import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import styles from "./Login.module.css";
import { useNavigate } from "react-router";
import CryptoJS from "crypto-js";
import { message } from "antd";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
  }, []);

  const onFinish = (values) => {
    if (values?.username === "admin" && values?.password === "admin") {
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(values),
        process.env.REACT_APP_SECRET_KEY
      ).toString();
      localStorage.setItem("datakey", encryptedData);
      navigate("/admin/register-advise");
    } else {
      message.error(
        "Tài khoản hoặc mật khẩu không chính xác vui lòng thử lại!"
      );
      localStorage.clear();
    }
  };

  const linkTag = document.createElement("link");
  linkTag.rel = "stylesheet";
  linkTag.href =
    "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap";
  document.head.appendChild(linkTag);

  return (
    <div className={styles.wrapper}>
      <div class={styles.form_box}>
        <form class={styles.login_form} onSubmit={handleSubmit(onFinish)}>
          <h1 style={{ fontSize: "28px" }}>Trang quản trị</h1>
          <div class={styles.input_box}>
            <div>
              <ion-icon name="mail-outline"></ion-icon>
              <input
                type="text"
                required
                name="username"
                {...register("username")}
              />
              <label>Tài khoản</label>
            </div>
          </div>

          <div class={styles.input_box}>
            <div>
              <ion-icon name="key-outline"></ion-icon>
              <input
                type="password"
                required
                name="pass"
                {...register("password")}
              />
              <label>Password</label>
            </div>
            <ion-icon name="eye-outline"></ion-icon>
          </div>

          <button type="submit" class={styles.submit_btn}>
            Đăng nhập
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
