import React, { useEffect, useState } from "react";
import Introduce from "../../common/introduce";
import DentalService from "../../common/dental-service";
import MiddleBanner from "./MiddleBanner";
import DoctorList from "../../common/doctor-list";
import Clinic from "./Clinic";
import Review from "../../common/review";
import BottomAdviseForm from "./BottomAdviseForm";
import HotlineBanner from "./HotlineBanner";
import Carousel from "../../carousel/Carousel";
import ModalAdvise from "../../advise/modalAdvise";
import IntroduceImage from "../../../asset/logo-and-service/image16.jpg";
import PreviewBox from "../../common/preview-box";
import { getAllNews } from "../../../api/news/api";
import Div from "../../div";

function MainHome() {
  const [openAdviseModal, setOpenAdviseModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllNews()
      .then((res) => {
        const data = res.data;
        data && setDataSource(data);
      })
      .catch((err) => console.log(err));

    const modalInterval = setTimeout(() => {
      setOpenAdviseModal(true);
    }, 8000);

    return () => {
      clearInterval(modalInterval);
    };
  }, []);

  const styles = {
    container: "my-container py-[40px] h-[500px] lg:h-[460px]",
    title:
      "uppercase text-p-color font-bold text-[23px] text-center mb-[10px] md:text-[26px] lg:text-4xl",
  };

  return (
    <main>
      <ModalAdvise isOpen={{ openAdviseModal, setOpenAdviseModal }} />

      <section>
        <Carousel />
      </section>

      <section className="bg-[#f7f9fb]">
        <Introduce introImage={IntroduceImage} changeFlexOrder={false} />
      </section>

      <section>
        <DentalService isServicePage={false} />
      </section>

      <section>
        <MiddleBanner />
      </section>

      <section>
        <DoctorList />
      </section>

      <section>
        <Clinic />
      </section>

      <section className="relative">
        <Review />
      </section>

      <section className="relative">
        <Div>
          <h2 className={styles.title}>TIN TỨC</h2>
        </Div>
        <PreviewBox previewList={dataSource} pageSize={6} type={"news"} />
      </section>

      <section>
        <BottomAdviseForm />
      </section>

      <section>
        <HotlineBanner />
      </section>
    </main>
  );
}

export default MainHome;
