import axios from "axios";

const BASE_URL_SERVICE = `${process.env.REACT_APP_DOMAIN_API}api/client-data`;

export async function getAllCustomerDocuments() {
    const response = await axios.get(BASE_URL_SERVICE);
    return response?.data || [];
}

export async function getCustomerDocument(id) {
    const response = await axios.get(`${BASE_URL_SERVICE}/${id}`);

    return response?.data || {};
}

export async function postCustomerDocument(data) {
    const response = await axios.post(BASE_URL_SERVICE, data);

    return response?.data || {};
}

export async function updateCustomerDocument(id, data) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/update`, data);

    return response?.data || {};
}

export async function deleteCustomerDocument(id) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/delete`);

    return response?.data || {};
}
