import React, { useState } from "react";
import Div from "../../div";
import ModalAdvise from "../../advise/modalAdvise";

function PriceTable({ servicePrice }) {
  const [openAdviseModal, setOpenAdviseModal] = useState(false);

  return (
    <Div>
      <ModalAdvise isOpen={{ openAdviseModal, setOpenAdviseModal }} />

      {Object.keys(servicePrice).length > 0
        ? Object.keys(servicePrice).map((e, i) => {
            return (
              <Div
                key={i}
                className="my-container py-[30px] lg:pt-[60px] lg:pb-[30px]"
              >
                <Div className="text-center">
                  <h2 className="text-p-color text-xl font-bold py-[8px] leading-[33px] relative after:content-[''] after:absolute after:bottom-0 after:left-[50%] after:translate-x-[-50%] after:w-[90px] after:h-[3.5px] after:bg-p-color lg:text-2xl lg uppercase">
                    BÁO GIÁ DỊCH VỤ {servicePrice[e][0].dentalServicesName}
                  </h2>
                  <p className="py-[15px] text-lg">
                    Trân trọng gửi tới quý khách hàng bảng báo giá gói dịch vụ
                    của Nha khoa Phấn Hoa:
                  </p>
                </Div>

                <Div className="flex items-center justify-center w-full lg:mt-[30px]">
                  <table className="w-full">
                    <tbody>
                      {servicePrice[e].map((item, i) => {
                        const addDotsToNumber = (number) => {
                          let formatter = new Intl.NumberFormat("vi-VN");
                          let formattedNumber = formatter.format(number);

                          return formattedNumber;
                        };

                        const fromPrice = addDotsToNumber(item.fromPrice);
                        const toPrice = addDotsToNumber(item.toPrice);

                        return (
                          <tr key={i} className="odd:bg-[#f5f5f5]">
                            <td className="p-[10px] w-[50%] border-[3px] border-[#f8f8f8] lg:p-[15px]">
                              <p className="text-base font-semibold">
                                {item.name}
                              </p>
                            </td>
                            <td className="p-[10px] border-[3px] border-[#f8f8f8] text-sm lg:p-[15px]">
                              <Div className="flex flex-wrap items-center gap-[5px]">
                                <span>
                                  {fromPrice}
                                  <sup>đ</sup>
                                </span>

                                <span>
                                  {item.noteFromPrice &&
                                    `(${item.noteFromPrice})`}
                                </span>

                                {item?.toPrice && item.toPrice > 0 && (
                                  <span>-</span>
                                )}

                                {item.toPrice > 0 && (
                                  <span>
                                    {toPrice}
                                    <sup>đ</sup>
                                  </span>
                                )}

                                <span>
                                  {item.noteToPrice && `(${item.noteToPrice})`}
                                </span>

                                <span>{item.unit && `/ ${item.unit}`}</span>

                                <span>{item.note && ` (${item.note})`}</span>
                              </Div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Div>

                <Div className="py-[30px] flex items-center justify-center">
                  <button
                    onClick={() => setOpenAdviseModal(true)}
                    className="w-[185px] h-[45px] flex items-center justify-center gap-[8px] rounded-full bg-right bg-gradient-to-r from-[#3866c9_50%] to-[#1d48a6_0] text-white bg-[length:200%_200%] hover:bg-left duration-300"
                  >
                    <span>
                      <img
                        src="https://nhakhoahungcuong.com.vn/wp-content/themes/doanhnghiep/images/icon-taytrangrang-w.png"
                        alt=""
                      />
                    </span>
                    <span className="uppercase text-white font-bold">
                      ĐĂNG KÝ NGAY
                    </span>
                  </button>
                </Div>
              </Div>
            );
          })
        : servicePrice && (
            <Div className="h-[500px] flex flex-col items-center justify-center gap-[20px]">
              <Div className="rounded-lg overflow-hidden">
                <img
                  src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                  alt=""
                />
              </Div>

              <p className="text-zinc-500 font-bold text-3xl">
                Thông tin chi tiết bảng giá đang được cập nhật thêm
              </p>
            </Div>
          )}
    </Div>
  );
}

export default PriceTable;
