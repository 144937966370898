import React from "react";
import Header from "../header";
import { Link, Outlet } from "react-router-dom";
import Footer from "../footer";
import { FaFacebookMessenger } from "react-icons/fa";
import Div from "../div";

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Div className="w-full h-full">
        <Link
          to={"https://www.facebook.com/messages/t/100055890746561"}
          target="_blank"
          className="text-3xl bottom-[30px] left-[15px] fixed lg:text-5xl text-p-color lg:bottom-[80px] lg:left-[30px] z-50 shadow-2xl"
        >
          <FaFacebookMessenger />
        </Link>
      </Div>
      <Footer />
    </>
  );
}

export default Layout;
