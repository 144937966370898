import { Tag, Tooltip } from "antd";

export const columnRegis = [
  {
    title: "Ngày đăng ký",
    dataIndex: "createDate",
    ellipsis: true,
    width: "10%",
  },
  {
    title: "Họ tên",
    dataIndex: "userName",
    ellipsis: true,
  },
  {
    title: "Số điện thoại",
    dataIndex: "phoneNumber",
    ellipsis: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    ellipsis: true,
  },
  {
    title: "Dịch vụ đăng ký",
    dataIndex: "dentalServicesName",
    ellipsis: true,
    width: "15%",
    render: (dentalServicesName) => (
      <Tooltip placement="topLeft" title={dentalServicesName}>
        {dentalServicesName}
      </Tooltip>
    ),
  },
  {
    title: "Mô tả yêu cầu",
    dataIndex: "content",
    ellipsis: true,
    render: (content) => (
      <Tooltip placement="topLeft" title={content}>
        {content}
      </Tooltip>
    ),
  },
  {
    title: "Trạng thái",
    dataIndex: "status",
    ellipsis: true,
    editable: true,
    width: "12%",
  },
  {
    dataIndex: "menu",
    fixed: "right",
    width: "5%",
  },
];

export const columnPrice = [
  {
    title: "Ngày tạo",
    dataIndex: "createDate",
    ellipsis: true,
  },
  {
    title: "Nhóm dịch vụ",
    dataIndex: "serviceGroup",
    ellipsis: true,
  },
  {
    title: "Dịch vụ",
    dataIndex: "service",
    ellipsis: true,
  },
  {
    title: "Giá",
    dataIndex: "price",
    ellipsis: true,
  },
  {
    title: "Mô tả hoặc bảo hành",
    dataIndex: "note",
    ellipsis: true,
  },
  {
    dataIndex: "menu",
    fixed: "right",
    width: "10%",
  },
];

export const columnContact = [
  {
    title: "Ngày tạo",
    dataIndex: "createDate",
    ellipsis: true,
    width: "10%",
  },
  {
    title: "Họ tên",
    dataIndex: "userName",
    ellipsis: true,
  },
  {
    title: "Số điện thoại",
    dataIndex: "phoneNumber",
    ellipsis: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    ellipsis: true,
  },
  {
    title: "Nội dung",
    dataIndex: "content",
    ellipsis: true,
  },
  {
    title: "Trạng thái",
    dataIndex: "status",
    ellipsis: true,
    editable: true,
    width: "12%",
  },
  {
    dataIndex: "menu",
    fixed: "right",
    width: "5%",
  },
];
export const columnService = [
  {
    title: "Ngày tạo",
    dataIndex: "createDate",
    ellipsis: true,
  },
  {
    title: "Dịch vụ",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "Mô tả",
    dataIndex: "description",
    ellipsis: true,
  },
  {
    dataIndex: "menu",
    fixed: "right",
    width: "10%",
  },
];

export const columnProduct = [
  {
    title: "Ngày tạo",
    dataIndex: "createDate",
    ellipsis: true,
  },
  {
    title: "Tên sản phẩm",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "Nhà cung cấp",
    dataIndex: "supplier",
    ellipsis: true,
  },
  {
    title: "Loại sản phẩm",
    dataIndex: "type",
    ellipsis: true,
  },
  {
    dataIndex: "menu",
    fixed: "right",
    width: "10%",
  },
];

export const columnContentWeb = [
  {
    title: "Ngày tạo",
    dataIndex: "createDate",
    ellipsis: true,
  },
  {
    title: "Tên dịch vụ",
    dataIndex: "dentalServicesName",
    ellipsis: true,
  },
  {
    title: "Mô tả ngắn",
    dataIndex: "title",
    ellipsis: true,
  },
  {
    title: "Mô tả",
    dataIndex: "content",
    ellipsis: true,
  },
  {
    dataIndex: "menu",
    fixed: "right",
    width: "10%",
  },
];

export function renderStatus(status) {
  switch (status) {
    case 1:
      return <Tag color={"#2db7f5"}>Chưa tư vấn</Tag>;
    case 2:
      return <Tag color={"#87d068"}>Đã tư vấn</Tag>;
    default:
      return "";
  }
}

export const columnNews = [
  {
    title: "Ngày tạo",
    dataIndex: "createDate",
    ellipsis: true,
  },
  {
    title: "Tin tức",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "Mô tả ngắn",
    dataIndex: "type",
    ellipsis: true,
  },
  {
    dataIndex: "menu",
    fixed: "right",
    width: "10%",
  },
];

export const columnCustomerDocuments = [
  {
    title: "Ngày tạo",
    dataIndex: "createDate",
    ellipsis: true,
  },
  {
    title: "Khách hàng",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "Mô tả ngắn",
    dataIndex: "type",
    ellipsis: true,
  },
  {
    dataIndex: "menu",
    fixed: "right",
    width: "10%",
  },
];
