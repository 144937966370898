import {message, Upload} from "antd";
import {useEffect, useState} from "react";
import {storage} from "../../../firebase";
import {deleteObject, getDownloadURL, ref, uploadBytes,} from "firebase/storage";
import {InboxOutlined} from "@ant-design/icons";

const UploadImage = (props) => {
    const validImageTypes = ["image/jpeg", "image/png"];
    const [imageUrl, setImageUrl] = useState([]);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (props.images) {
            setFileList(
                props.images?.map((url, index) => ({
                    uid: index.toString(),
                    name: `Image ${index + 1}`,
                    status: "done",
                    url: url,
                }))
            );
        }
    }, [props.images]);

    useEffect(() => {
        props.url(imageUrl)
    }, [imageUrl])

    const customRequest = async ({file, onSuccess, onError}) => {
        try {
            const storageRef = ref(storage, `dental-phanhoa/${file.name}`);
            const uploadTask = uploadBytes(storageRef, file);

            uploadTask
                .then((snapshot) => {
                    return getDownloadURL(storageRef);
                })
                .then((images) => {
                    setImageUrl([...imageUrl, images]);
                    message.success("Tải ảnh lên thành công");
                    onSuccess();
                })
                .catch((error) => {
                    console.log("Tải ảnh lên lỗi : ", error);
                    message.error("Tải ảnh lên thất bại");
                    onError();
                });
        } catch (error) {
            console.log("Tải ảnh lên lỗi : ", error);
            message.error("Tải ảnh lên thất bại");
            onError();
        }
    };

    const deleteImage = async (imageName) => {
        const storageRef = ref(storage, `dental-phanhoa/${imageName?.name}`);
        const url = ref(storage, `dental-phanhoa/${imageName.name}`);

        try {
            const downloadURL = await getDownloadURL(url);
            setImageUrl(imageUrl?.filter((item) => item !== downloadURL))
            await deleteObject(storageRef);
            console.log("Xóa ảnh thành công");
        } catch (error) {
            console.error("Có lỗi sảy ra khi xóa ảnh ", error);
        }
    };

    const beforeUpload = (file) => {
        const isImage = validImageTypes.includes(file.type);
        if (!isImage) {
            message.error(
                "Ảnh không hợp lệ vui lòng chọn đúng định dạng ảnh (jpg, png)!"
            );
        }
        return isImage;
    };

    return (
        <>
            <Upload.Dragger
                customRequest={customRequest}
                onRemove={deleteImage}
                beforeUpload={beforeUpload}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Chọn ảnh tải lên</p>
            </Upload.Dragger>
        </>
    );
};
export default UploadImage;
