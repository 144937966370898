import axios from "axios";

const BASE_URL_SERVICE = `${ process.env.REACT_APP_DOMAIN_API}api/price-sheets`;

export async function getAllPriceService() {
	const response = await axios.get(BASE_URL_SERVICE);

	return response?.data || [];
}

export async function postPriceService(data) {
	const response = await axios.post(BASE_URL_SERVICE, data);

	return response?.data || {};
}

export async function updatePriceService(id, data) {
	const response = await axios.put(`${BASE_URL_SERVICE}/${id}/update`, data);

	return response?.data || {};
}

export async function deletePriceService(id) {
	const response = await axios.put(`${BASE_URL_SERVICE}/${id}/delete`);

	return response?.data || {};
}
