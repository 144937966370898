import axios from "axios";

const BASE_URL_SERVICE = `${process.env.REACT_APP_DOMAIN_API}api/content-web`;

export async function getAllContentWeb() {
  const response = await axios.get(BASE_URL_SERVICE);
  return response?.data || [];
}

export async function getDetailContentWeb(contentId) {
  const response = await axios.get(`${BASE_URL_SERVICE}/${contentId}`);
  return response?.data || [];
}

export async function postContentWeb(data) {
  const response = await axios.post(BASE_URL_SERVICE, data);
  return response?.data || {};
}

export async function updateContentWeb(id, data) {
  const response = await axios.put(`${BASE_URL_SERVICE}/${id}/update`, data);
  return response?.data || {};
}

export async function deleteContentWeb(id) {
  const response = await axios.put(`${BASE_URL_SERVICE}/${id}/delete`);
  return response?.data || {};
}
