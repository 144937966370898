import React, { useEffect, useState } from "react";
import Div from "../div";
import { NavLink, createSearchParams, useNavigate } from "react-router-dom";
import { getAllService } from "../../api/service/api";

function Navbar() {
  const [sticky, setSticky] = useState(false);
  const [servicesContent, setServiceContent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllService()
      .then((res) => {
        const data = res.data;

        data && setServiceContent(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > 200) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`${
        sticky
          ? "fixed top-0 left-[50%] translate-x-[-50%] w-full z-20"
          : "block"
      }`}
    >
      <Div>
        <ul className='flex items-center justify-center py-[8px] bg-p-color text-white'>
          <li>
            <NavLink className={styles.navItems} to={"/"}>
              TRANG CHỦ
            </NavLink>
          </li>

          <li>
            <NavLink className={styles.navItems} to={"/about"}>
              VỀ CHÚNG TÔI
            </NavLink>
          </li>

          <li className={` relative group`}>
            <NavLink className={styles.navItems} to={"/service"}>
              DỊCH VỤ
            </NavLink>

            <div className={styles.servieceBox}>
              <ul>
                {servicesContent?.map((e, i) => {
                  return (
                    <li key={i} className={styles.serviceItems}>
                      <Div
                        onClick={() =>
                          navigate({
                            pathname: "/content-web",
                            search: `?${createSearchParams({
                              content: e.id,
                              type: "dental-service",
                            }).toString()}`,
                          })
                        }
                        className={"block"}
                      >
                        {e?.name}
                      </Div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>

          <li>
            <NavLink className={styles.navItems} to={"/service-price"}>
              BẢNG GIÁ
            </NavLink>
          </li>

          <li>
            <NavLink className={styles.navItems} to={"/dental-product"}>
              SẢN PHẨM NHA KHOA
            </NavLink>
          </li>
          <li>
            <NavLink className={styles.navItems} to={"/news"}>
              TIN TỨC
            </NavLink>
          </li>
          {/* <li>
            <NavLink className={styles.navItems} to={"/customer"}>
              KHÁCH HÀNG
            </NavLink>
          </li> */}
          <li>
            <NavLink className={styles.navItems} to={"/contact"}>
              LIÊN HỆ
            </NavLink>
          </li>
        </ul>
      </Div>
    </nav>
  );
}

const styles = {
  navItems:
    "px-[13px] py-[6px] border-r border-hover-color hover:bg-hover-color duration-300 cursor-pointer",
  servieceBox:
    "absolute translate-y-[55px] translate-x-[-50%] left-[50%] duration-300 w-[220px] opacity-0 invisible bg-p-color shadow-2xl group-hover:translate-y-[8px] group-hover:opacity-100 group-hover:visible z-10",
  serviceItems:
    "py-[10px] px-[15px] border hover:bg-hover-color duration-300 cursor-pointer",
};

export default Navbar;
