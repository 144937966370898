import React, { useEffect } from "react";
import NavigateBanner from "../../common/navigate-banner";
import Introduce from "../../common/introduce";
import DoctorList from "../../common/doctor-list";
import Review from "../../common/review";
import AboutVision from "./AboutVision";
import Culture from "./Culture";
import PeopleAbout from "./PeopleAbout";
import IntroduceImage from "../../../asset/logo-and-service/clinic.jpg";
import History from "./History";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section>
        <NavigateBanner navigatePage={"Về chúng tôi"} />
      </section>

      <section className='bg-[#f7f9fb]'>
        <Introduce introImage={IntroduceImage} changeFlexOrder={true} />
      </section>

      <section>
        <History />
      </section>

      <section>
        <AboutVision />
      </section>

      <section>
        <Culture />
      </section>

      <section>
        <PeopleAbout />
      </section>

      <section className='bg-[#f7f9fb]'>
        <DoctorList />
      </section>

      <section className='relative'>
        <Review />
      </section>
    </>
  );
}

export default About;
