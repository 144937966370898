import { Form, Input, message, Modal } from "antd";
import UploadImage from "../../common/UploadImage";
import { useEffect, useState } from "react";
import { postProduct, updateProduct } from "../../../../api/product/api";
import CkEditorCustome from "../../common/CkEditorCustom";

const AddProduct = (props) => {
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(props?.data?.description);


  useEffect(() => {
    if (props.data && props.isVisible) {
      form.setFieldsValue(props.data);
    } else {
      form.resetFields();
    }
  }, [form, props.data, props.isVisible]);

  const onFinish = (values) => {
    setLoading(true);
    const value = { ...values, images: images, description: content };
    if (props.data) {
      updateProduct(props.data.id, value)
        .then((res) => {
          message.success("Sửa sản phẩm thành công");
          form.resetFields();
          props.onCancel();
        })
        .finally(() => setLoading(false));
    } else {
      postProduct(value)
        .then((res) => {
          message.success("Thêm sản phẩm thành công");
          form.resetFields();
          props.onCancel();
        })
        .finally(() => setLoading(false));
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
  };

  return (
    <Modal
      title={props.data ? "Sửa thông tin sản phẩm" : "Thêm mới sản phẩm"}
      centered
      open={props.isVisible}
      onOk={() => form.submit()}
      onCancel={props.onCancel}
      destroyOnClose={true}
      okButtonProps={{
        className: "bg-admin-btn-primary",
      }}
      okText={props.data ? "Cập nhật" : "Tạo mới"}
      confirmLoading={loading}
      width={1000}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Tên sản phẩm"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên sản phâm!",
            },
          ]}
        >
          <Input placeholder={"Nhập tên sản phẩm"} />
        </Form.Item>
        <Form.Item
          label="Nhà cung cấp"
          name="supplier"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập nhà cung cấp!",
            },
          ]}
        >
          <Input placeholder={"Nhập nhà cung cấp"} />
        </Form.Item>
        <Form.Item
          label="Loại sản phẩm"
          name="type"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập loại sản phẩm!",
            },
          ]}
        >
          <Input placeholder={"Nhập loại sản phẩm"} />
        </Form.Item>
        <CkEditorCustome
          label={"Mô tả sản phẩm"}
          description={props?.data?.description}
          setContent={handleChange}
        />
        <UploadImage url={(e) => setImages(e)} />
      </Form>
    </Modal>
  );
};
export default AddProduct;
