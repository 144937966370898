import { Form, Input, message, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import UploadImage from "../../common/UploadImage";
import { getAllService } from "../../../../api/service/api";
import { Option } from "antd/es/mentions";
import {
  postContentWeb,
  updateContentWeb,
} from "../../../../api/service/api-content";
import CkEditorCustome from "../../common/CkEditorCustom";

const FromDetail = (props) => {
  const [form] = Form.useForm();
  const [urlImage, setUrlImage] = useState([]);
  const [dataService, setDataService] = useState([]);
  const [content, setContent] = useState(props?.data?.content);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllService().then((res) => {
      setDataService(res?.data);
    });
  }, []);

  useEffect(() => {
    if (props.data && props.isVisible) {
      form.setFieldsValue(props.data);
    } else {
      form.resetFields();
    }
  }, [form, props.data, props.isVisible]);

  const onFinish = (values) => {
    const value = { ...values, content: content, images: urlImage };
    setLoading(true);

    if (props.data) {
      updateContentWeb(props.data.id, value)
        .then((res) => {
          message.success("Sửa thông tin dịch vụ thành công");
          props.onCancel();
          form.resetFields();
        })
        .finally(() => setLoading(false));
    } else {
      postContentWeb(value)
        .then((res) => {
          message.success("Thêm thông tin dịch vụ thành công");
          props.onCancel();
          form.resetFields();
        })
        .finally(() => setLoading(false));
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
  };

  return (
    <Modal
      title={props.data ? "Sửa thông tin dịch vụ" : "Thêm thông tin dịch vụ"}
      centered
      open={props.isVisible}
      onOk={() => form.submit()}
      onCancel={props.onCancel}
      destroyOnClose={true}
      width={1000}
      confirmLoading={loading}
      okButtonProps={{
        className: "bg-admin-btn-primary",
      }}
      okText={props.data ? "Cập nhật" : "Tạo mới"}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Tên dịch vụ"
          name="dentalServicesId"
          placeholder="Chọn dịch vụ muốn thêm thông tin"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn dịch vụ!",
            },
          ]}
        >
          <Select
            placeholder="Chọn dịch vụ"
            style={{
              width: "100%",
            }}
            allowClear={true}
            popupMatchSelectWidth={false}
            filterOption={false}
          >
            {dataService?.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Mô tả ngắn"
          name="title"
          placeholder="Nhập nội dung mô tả"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập nội dung thông tin!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <CkEditorCustome
          label={"Nội dung dịch vụ"}
          description={props?.data?.content}
          setContent={handleChange}
        />

        <UploadImage url={(e) => setUrlImage(e)} images={props.data?.images} />
      </Form>
    </Modal>
  );
};
export default FromDetail;
