import axios from "axios";

const BASE_URL_SERVICE = `${process.env.REACT_APP_DOMAIN_API}api/products`;

export async function getAllProducts() {
    const response = await axios.get(BASE_URL_SERVICE);
    return response?.data || [];
}

export async function getProduct(id) {
    const response = await axios.get(`${BASE_URL_SERVICE}/${id}`);

    return response?.data || {};
}

export async function postProduct(data) {
    const response = await axios.post(BASE_URL_SERVICE, data);

    return response?.data || {};
}

export async function updateProduct(id, data) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/update`, data);

    return response?.data || {};
}

export async function deleteProduct(id) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/delete`);

    return response?.data || {};
}
