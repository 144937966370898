import axios from "axios";

const BASE_URL_SERVICE = `${ process.env.REACT_APP_DOMAIN_API}api/contacts`;

export async function getAllContactsByType(type) {
    const response = await axios.get(`${BASE_URL_SERVICE}/?type=`+ type);

    return response?.data || [];
}

export async function createContact( data) {
    const response = await axios.post(BASE_URL_SERVICE, data);

    return response?.data || {};
}

export async function updateContactByType(id, data) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/update`, data);

    return response?.data || {};
}

export async function updateStatusContactByType(id, data) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/update-status`, data);

    return response?.data || {};
}


export async function deleteContactTypeByType(id) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/delete`);

    return response?.data || {};
}
