import axios from "axios";

const BASE_URL_SERVICE = `${process.env.REACT_APP_DOMAIN_API}api/news`;

export async function getAllNews() {
    const response = await axios.get(BASE_URL_SERVICE);
    return response?.data || [];
}

export async function getNew(id) {
    const response = await axios.get(`${BASE_URL_SERVICE}/${id}`);

    return response?.data || {};
}

export async function postNew(data) {
    const response = await axios.post(BASE_URL_SERVICE, data);

    return response?.data || {};
}

export async function updateNew(id, data) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/update`, data);

    return response?.data || {};
}

export async function deleteNew(id) {
    const response = await axios.put(`${BASE_URL_SERVICE}/${id}/delete`);

    return response?.data || {};
}
